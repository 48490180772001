const STATES = [
  {
    title: 'Pendientes',
    type: 'pending',
  },
  {
    title: 'Bloqueados',
    type: 'blocked',
  },
  {
    title: 'Archivados',
    type: 'ignored',
  },
];

export default STATES;
