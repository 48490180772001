import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from '../../utils';

const ImageCrop = ({ image, setImage }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels);
      setImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  const onClose = useCallback(() => {
    setImage(null);
  }, []);

  return (
    <div className="absolute w-full h-full z-50 flex justify-center items-center">
      <div
        style={{
          width: '800px',
          height: '600px',
        }}
        className="absolute"
      >
        <div className="w-full h-full">
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={1}
            style={{
              containerStyle: {
                borderRadius: '16px',
              },
            }}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
          <div
            onClick={showCroppedImage}
            className="px-4 py-2 bg-blue-500 rounded-md cursor-pointer absolute right-0 bottom-0 mr-4 mb-4"
          >
            <p className="text-white">Seleccionar</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageCrop;
