import { ActionTypes } from './types';

const Actions = (dispatch) => {
  return {
    setCompanies: (companies) => {
      dispatch({ type: ActionTypes.SET_COMPANIES, companies });
    },
    updateCompany: (company) => {
      dispatch({ type: ActionTypes.UPDATE_COMPANY, company });
    },
    setSelectedCompany: (company) => {
      dispatch({ type: ActionTypes.SET_SELECTED_COMPANY, company });
    },
    toggleCompanyModal: () => {
      dispatch({ type: ActionTypes.TOGGLE_COMPANY_MODAL });
    },
  };
};

export default Actions;
