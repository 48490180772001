import React from 'react';
import { UsersTable } from '../../components';

const Users = () => {
  return (
    <div className="h-full w-full">
      <UsersTable />
    </div>
  );
};

export default Users;
