import {
  get as getAxios,
  patch as patchAxios,
} from "../utils/requests-handlers";

const { REACT_APP_API_URL } = process.env;

export function getFilteredEvents(payload = {}) {
  return getAxios(`${REACT_APP_API_URL}/v1/events/search`, payload);
}
export function getFilteredJobs(payload = {}) {
  return getAxios(`${REACT_APP_API_URL}/v1/jobs/search`, payload);
}

export function getFilteredCampaigns(payload = {}) {
  return getAxios(`${REACT_APP_API_URL}/v1/crowdfunding/campaigns`, payload);
}

export function getFilteredPrograms(payload = {}) {
  return getAxios(
    `${REACT_APP_API_URL}/v1/educations/programs/search`,
    payload
  );
}

export function getFilteredCourses(payload = {}) {
  return getAxios(`${REACT_APP_API_URL}/v1/educations/courses/search`, payload);
}

export function updateCategoryItemStatus(payload = {}) {
  return patchAxios(`${REACT_APP_API_URL}/v1/admin/categories/`, payload);
}
