import React, { useState } from "react";
import _ from "lodash";
import moment from "moment";
import { VscCircleSlash, VscCheck } from "react-icons/vsc";
import { FaFilter } from "react-icons/fa";
import { FiEdit2, FiCheck, FiX } from "react-icons/fi";
import shortUuid from "short-uuid";
import { useUser, useUsers } from "../contexts/users/hooks";
import Paginator from "./Paginator";
import usePagination from "../hooks/usePagination";
import { useAppState } from "../contexts/app";
import Tooltip from "./Tooltip";

const PageClaimRequestsTable = ({
  requests,
  updateUser,
  updatePageClaimRequest,
}) => {
  const appState = useAppState();
  return (
    <div className="py-6 overflow-x-auto flex-grow">
      <div className="inline-block w-full overflow-hidden">
        <table className="w-full min-w-full leading-normal table-fixed">
          <thead>
            <tr>
              <th className="w-1/5 px-5 py-3 text-left text-xs font-semibold text-gray-600 tracking-wider">
                Nombre
              </th>
              <th className="w-1/5 px-5 py-3 text-left text-xs font-semibold text-gray-600 tracking-wider">
                Documentos
              </th>
              <th className="w-1/6 px-5 py-3 text-left text-xs font-semibold text-gray-600 tracking-wider">
                Empresa
              </th>
              <th className="w-1/6 px-5 py-3 text-left text-xs font-semibold text-gray-600 tracking-wider">
                Solicitud
              </th>
              <th className="w-2/12 px-5 py-3 text-left text-xs font-semibold text-gray-600 tracking-wider">
                Verificación
              </th>
              <th className="w-2/12 px-5 py-3 text-left text-xs font-semibold text-gray-600 tracking-wider">
                Descripción
              </th>
              <th className="w-1/12 px-2 py-3 text-left text-xs font-semibold text-gray-600 tracking-wider"></th>
            </tr>
          </thead>
          <tbody>
            {_.values(requests).map(
              ({
                user,
                created_at,
                status,
                _id,
                documents,
                company,
                description,
              }) => {
                return (
                  <tr
                    key={`store_request__${shortUuid.generate()}`}
                    className={`${
                      !!user.removed ? "text-gray-500" : "text-gray-900"
                    }`}
                  >
                    <td className="px-5 py-5 border-b border-gray-200">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 w-10 h-10">
                          <img
                            className={`w-full h-full rounded-full ${
                              !!user.removed && "opacity-50"
                            }`}
                            src={user.profile_photo}
                            alt={user.first_name}
                          />
                        </div>
                        <div className="ml-3">
                          <p className="break-all ">
                            {`${user.first_name} ${user.last_name}`}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200">
                      {documents.map((document) => {
                        return (
                          <a
                            key={`document__${document.name}`}
                            className="break-all cursor-pointer underline text-blue-600"
                            href={document.url}
                            target="_blank"
                          >
                            {document.name}
                          </a>
                        );
                      })}
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200">
                      <p className="break-all text-blue-600">{company.name}</p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200">
                      <p className="break-all">
                        {_.upperFirst(moment(created_at).fromNow())}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200">
                      <div className="flex items-center">
                        {status === "verifying" ? (
                          <>
                            <div className="border-2 border-gray-200 rounded-full">
                              <FiX
                                onClick={() =>
                                  updatePageClaimRequest(_id, {
                                    status: "declined",
                                  })
                                }
                                className="cursor-pointer text-red-600 m-2 text-lg"
                              />
                            </div>
                            <div className="ml-3 border-2 border-gray-200 rounded-full">
                              <FiCheck
                                onClick={() =>
                                  updatePageClaimRequest(_id, {
                                    status: "approved",
                                  })
                                }
                                className="cursor-pointer text-blue-500 m-2 text-lg"
                              />
                            </div>
                          </>
                        ) : status === "approved" ? (
                          <div className="border rounded-full border-gray-200">
                            <p className="m-1 mx-3 text-blue-500">Aprobado</p>
                          </div>
                        ) : (
                          <div className="border rounded-full border-gray-200">
                            <p className="m-1 mx-3 text-red-600">Denegado</p>
                          </div>
                        )}
                      </div>
                    </td>

                    <td className="px-5 py-5 border-b border-gray-200">
                      <Tooltip message={description}>
                        {_.truncate(description, { length: 40 })}
                      </Tooltip>
                    </td>

                    {appState.state.user.username !== user.username && (
                      <td className="px-5 py-5 border-b border-gray-200">
                        {!user.removed ? (
                          <VscCircleSlash
                            onClick={() => {
                              updateUser(
                                "PageClaimRequest",
                                _id,
                                user.username,
                                {
                                  removed: true,
                                }
                              );
                            }}
                            className="cursor-pointer text-3xl text-gray-600"
                          />
                        ) : (
                          <VscCheck
                            onClick={() => {
                              updateUser(
                                "PageClaimRequest",
                                _id,
                                user.username,
                                {
                                  removed: false,
                                }
                              );
                            }}
                            className="cursor-pointer text-3xl text-gray-600"
                          />
                        )}
                      </td>
                    )}
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PageClaimRequestsTable;
