import React from 'react';
import _ from 'lodash';
import shortUuid from 'short-uuid';
import {
  VscCircleSlash,
  VscCheck,
  VscChevronRight,
  VscAdd,
} from 'react-icons/vsc';
import { AiOutlineUndo } from 'react-icons/ai';
import moment from 'moment';
import { useReport } from '../contexts/reports/hooks';

const CourseReportsTable = ({ reports, handleUpdateReportStatus }) => {
  const {
    actions: { selectReport },
  } = useReport();
  return (
    <div className="py-6 overflow-x-auto flex-grow">
      <div className="inline-block w-full overflow-hidden">
        <table className="w-full min-w-full leading-normal table-fixed">
          <thead>
            <tr>
              <th className="w-1/5 px-5 py-3 text-left text-xs font-semibold text-gray-600 tracking-wider">
                Nombre
              </th>
              <th className="w-1/5 px-5 py-3 text-left text-xs font-semibold text-gray-600 tracking-wider">
                Empresa
              </th>
              <th className="w-1/6 px-5 py-3 text-left text-xs font-semibold text-gray-600 tracking-wider">
                Titulo
              </th>
              <th className="w-1/6 px-5 py-3 text-left text-xs font-semibold text-gray-600 tracking-wider">
                Reportes
              </th>
              <th className="w-1/6 px-5 py-3 text-left text-xs font-semibold text-gray-600 tracking-wider">
                Usuario desde
              </th>
              <th className="w-1/5 px-5 py-3 text-left text-xs font-semibold text-gray-600 tracking-wider">
                Bloquear
              </th>
            </tr>
          </thead>
          <tbody>
            {_.values(reports).map(
              ({ content, content_type, count, status, _id }) => {
                return content_type === 'Course' ? (
                  <tr key={`course__${shortUuid.generate()}`}>
                    <td className="px-5 py-5 border-b border-gray-200">
                      <div className="flex items-center cursor-pointer">
                        <div className="flex-shrink-0 w-10 h-10">
                          <img
                            className={`w-full h-full rounded-full`}
                            src={content.owner.profile_photo}
                            alt={content.owner.first_name}
                          />
                        </div>
                        <div className="ml-3">
                          <p className="break-all ">
                            {`${content.owner.first_name} ${content.owner.last_name}`}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200">
                      <p className="break-all  whitespace-no-wrap">
                        {content.company.name}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200">
                      <p className="break-all  whitespace-no-wrap">
                        {content.title}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200">
                      <div
                        onClick={() =>
                          selectReport({
                            _id,
                            count,
                            content,
                            content_type,
                            status,
                          })
                        }
                        className="w-full inline-flex items-center text-blue-500"
                      >
                        <p className="cursor-pointer mr-4">{count} reportes</p>
                        <VscChevronRight className="text-2xl cursor-pointer" />
                      </div>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200">
                      <p className="break-all whitespace-no-wrap">
                        {!!content.owner.created_at
                          ? _.upperFirst(
                              moment(content.owner.created_at).fromNow()
                            )
                          : 'Ninguna'}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200">
                      {status === 'pending' ? (
                        <>
                          <div
                            onClick={() => {
                              handleUpdateReportStatus(_id, 'ignored');
                            }}
                            className="inline-flex items-center mr-6 cursor-pointer"
                          >
                            <VscCheck className="text-gray-500 mr-1 text-xl" />
                            <p className="">Archivar</p>
                          </div>
                          <div
                            onClick={() => {
                              handleUpdateReportStatus(_id, 'blocked');
                            }}
                            className="inline-flex items-center cursor-pointer"
                          >
                            <VscCircleSlash className="text-gray-500 mr-1 text-xl" />
                            <p className="">Bloquear</p>
                          </div>
                        </>
                      ) : (
                        <div
                          onClick={() => {
                            handleUpdateReportStatus(_id, 'pending');
                          }}
                          className="inline-flex items-center cursor-pointer"
                        >
                          {status === 'blocked' ? (
                            <VscAdd className="text-gray-500 mr-1 text-xl " />
                          ) : (
                            <AiOutlineUndo className="text-gray-500 mr-1 text-xl " />
                          )}
                          <p className="">
                            {status === 'blocked'
                              ? 'Desbloquear'
                              : 'Desarchivar'}
                          </p>
                        </div>
                      )}
                    </td>
                  </tr>
                ) : null;
              }
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CourseReportsTable;
