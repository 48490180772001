const STATES = [
  {
    title: "Pendientes",
    type: "waiting",
  },
  {
    title: "Aprobados",
    type: "approved",
  },
  {
    title: "Rechazados",
    type: "rejected",
  },
  {
    title: "Archivados",
    type: "archived",
  },
];

export default STATES;
