import _ from "lodash";
import React from "react";
import Lottie from "lottie-react-web";
import animation from "../../../assets/lottie/9914-loading-success-fail-spinner-stroke-update.json";

const CustomTable = ({
  data,
  headers,
  loading,
  rowStyle,
  optionsComponent,
  handleSelectItem,
  customPlaceholder,
  containerClassName,
}) => {
  return (
    <div
      className={containerClassName + " h-full"}
      style={{ overflowY: "auto" }}
    >
      <table className="w-full table-fixed">
        <thead>
          <tr>
            {headers.map((header, i) => {
              return (
                <th
                  key={`header_${i}`}
                  style={{ position: "sticky", top: 0 }}
                  className={
                    "text-left w-1/5 px-5 py-3 text-xs font-semibold text-gray-600 tracking-wider bg-white"
                  }
                >
                  {header.title}
                </th>
              );
            })}
          </tr>
        </thead>

        {loading && (
          <tbody>
            <tr>
              <td
                className="px-5 py-5 border-b border-gray-200"
                colSpan={headers.length}
              >
                <Lottie
                  width="40%"
                  options={{
                    animationData: animation,
                  }}
                />
              </td>
            </tr>
          </tbody>
        )}

        {!loading && (
          <tbody className="overflow-auto h-full">
            {!data.length && (
              <tr className={"hover:bg-gray-200 text-center h-24"}>
                <td className="px-5 py-5" colSpan={headers.length}>
                  {!!customPlaceholder
                    ? customPlaceholder
                    : "Aun no hay datos para mostrar"}
                </td>
              </tr>
            )}
            {!!data &&
              data.map((row, i) => {
                return (
                  <tr
                    style={rowStyle}
                    key={`data_row_${i}`}
                    className={"hover:bg-gray-200 h-24"}
                  >
                    {headers.map((header, iheader) => {
                      switch (header.uid) {
                        case "options":
                          return (
                            !!optionsComponent && (
                              <td
                                key={`td_options_${iheader}`}
                                className="px-5 py-5 border-b border-gray-200"
                              >
                                {optionsComponent(row)}
                              </td>
                            )
                          );

                        case "is_completed":
                          return (
                            <td
                              key={`td_name_${iheader}`}
                              className={
                                "cursor-pointer px-5 py-5 border-b border-gray-200"
                              }
                            >
                              <div className={"flex items-center"}>
                                <img
                                  className={"w-10 h-10 rounded-full"}
                                  src={row.profile_photo}
                                />
                                <p className={"ml-3"}>
                                  {row.is_completed
                                    ? `${row.first_name} ${row.last_name}`
                                    : "Usuario nuevo"}
                                </p>
                              </div>
                            </td>
                          );

                        case "publisher":
                        case "user":
                        case "owner":
                          return (
                            <td
                              key={`td_owner_${iheader}`}
                              className={
                                "cursor-pointer px-5 py-5 border-b border-gray-200"
                              }
                            >
                              <div className={"flex items-center  "}>
                                <img
                                  className={"w-10 h-10 rounded-full"}
                                  src={row[header.uid].profile_photo}
                                />
                                <p className={"ml-3"}>
                                  {`${row[header.uid].first_name} ${
                                    row[header.uid].last_name
                                  }`}
                                </p>
                              </div>
                            </td>
                          );

                        case "medias":
                          return (
                            <td
                              key={`td_medias_${iheader}`}
                              className={
                                "items-center border-b border-gray-200"
                              }
                            >
                              <a
                                target="_blank"
                                href={row[header.uid][0].url}
                                className="break-all cursor-pointer underline text-blue-600"
                              >
                                {"Video oficial"}
                              </a>
                            </td>
                          );

                        case "media":
                          return (
                            <td
                              key={`td_media_${iheader}`}
                              className="px-5 py-5 border-b border-gray-200 w-full"
                            >
                              {row[header.uid].map((media_file, i) => {
                                return (
                                  <>
                                    <a
                                      target="_blank"
                                      href={media_file.url}
                                      key={`media_file_${i}`}
                                      className="break-all cursor-pointer underline text-blue-600"
                                    >
                                      {`media_file_${i + 1}`}
                                    </a>
                                    <br />
                                  </>
                                );
                              })}
                            </td>
                          );

                        case "company":
                          return (
                            <td
                              key={`td_company_${iheader}`}
                              className="px-5 py-5 border-b border-gray-200"
                            >
                              {row[header.uid].name}
                            </td>
                          );

                        case "profession":
                          return (
                            <td
                              key={`td_profession_${iheader}`}
                              className="px-5 py-5 border-b border-gray-200"
                            >
                              {row[header.uid].name}
                            </td>
                          );

                        // case "description":
                        //   return (
                        //     <td
                        //       key={`td_description_${iheader}`}
                        //       onClick={() => handleSelectItem(row)}
                        //       className="px-5 py-5 border-b border-gray-200 cursor-pointer"
                        //     >
                        //       {_.truncate(row[header.uid], { length: 40 })}
                        //     </td>
                        //   );

                        default:
                          return (
                            <td
                              key={`td_${header.uid}_${iheader}`}
                              className="px-5 py-5 border-b border-gray-200 w-full"
                            >
                              {!!header.formatter
                                ? header.formatter(row[header.uid], row)
                                : row[header.uid]}
                            </td>
                          );
                      }
                    })}
                  </tr>
                );
              })}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default CustomTable;
