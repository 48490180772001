import { useCompanyPage, useCompanyPages } from "../contexts/companies/hooks";
import { VscCircleSlash, VscCheck } from "react-icons/vsc";
import React, { useCallback, useState } from "react";
import usePagination from "../hooks/usePagination";
import { getCompaniesReport } from "../api/audit";
import Paginator from "./Paginator";
import shortUuid from "short-uuid";
import moment from "moment";
import _ from "lodash";

const UsersTable = () => {
  const { page, limit, setLimit, setPage } = usePagination();
  const [name, setName] = useState("");
  const [loadingReport, setLoadingReport] = useState(false);
  const { loading, companies, companiesCount } = useCompanyPages({
    page,
    limit,
    name,
  });
  const {
    actions: { selectCompany, updateCompany },
  } = useCompanyPage();

  const handleDownloadReport = useCallback(() => {
    if (loadingReport) return;
    setLoadingReport(true);
    getCompaniesReport()
      .then(({ result }) => {
        window.open(result, "_blank");
      })
      .catch((error) => console.log(`Error descargando archivo`, error))
      .finally(() => setLoadingReport(false));
  }, [setLoadingReport, getCompaniesReport, loadingReport]);

  return (
    <div className="p-8 min-w-full bg-white shadow-md rounded-lg h-full flex flex-col">
      <div className="my-2 flex justify-between ">
        <h3 className="text-xl font-bold">Paginas de empresa</h3>
        <div className="flex items-center">
          <input
            type="search"
            placeholder="Buscar por nombre..."
            className="bg-purple-white shadow rounded border-0 p-3"
            onChange={(text) => {
              setName(text.target.value);
            }}
          />
        </div>
      </div>
      <div className="py-6 overflow-x-auto flex-grow">
        <div className="inline-block w-full overflow-hidden">
          <table className="w-full min-w-full leading-normal table-fixed">
            <thead>
              <tr>
                <th className="w-1/5 px-5 py-3 text-left text-xs font-semibold text-gray-600 tracking-wider">
                  Nombre
                </th>
                <th className="w-1/5 px-5 py-3 text-left text-xs font-semibold text-gray-600 tracking-wider">
                  Bio
                </th>
                <th className="w-1/6 px-5 py-3 text-left text-xs font-semibold text-gray-600 tracking-wider">
                  Link
                </th>
                <th className="w-1/6 px-5 py-3 text-left text-xs font-semibold text-gray-600 tracking-wider">
                  Dueño
                </th>
                <th className="w-1/5 px-5 py-3 text-left text-xs font-semibold text-gray-600 tracking-wider">
                  Creada desde
                </th>
                <th className="w-1/12 px-2 py-3 text-left text-xs font-semibold text-gray-600 tracking-wider">
                  Bloquear
                </th>
              </tr>
            </thead>
            <tbody>
              {!loading &&
                _.values(companies).map((company) => {
                  return (
                    <tr
                      key={`company__${shortUuid.generate()}`}
                      className={`${
                        !!company.removed ? "text-gray-500" : "text-gray-900"
                      }`}
                    >
                      <td className="px-5 py-5 border-b border-gray-200">
                        <div
                          onClick={() => selectCompany(company)}
                          className="flex items-center cursor-pointer"
                        >
                          <div className="flex-shrink-0 w-10 h-10">
                            <img
                              className={`w-full h-full rounded-full ${
                                !!company.removed && "opacity-50"
                              }`}
                              src={company.photo}
                              alt={company.name}
                            />
                          </div>
                          <div className="ml-3">
                            <p className="break-all">{company.name}</p>
                          </div>
                        </div>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200">
                        <p className="break-all">{company.bio}</p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200">
                        <p className="break-all">{company.link}</p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200">
                        <p className="break-all text-blue-500">{`${company.owner.first_name} ${company.owner.last_name}`}</p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200">
                        <p className="break-all">
                          {_.upperFirst(moment(company.created_at).fromNow())}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200">
                        {!company.removed ? (
                          <VscCircleSlash
                            onClick={() => {
                              updateCompany(company.uid, { removed: true });
                            }}
                            className="cursor-pointer text-3xl text-gray-600"
                          />
                        ) : (
                          <VscCheck
                            onClick={() => {
                              updateCompany(company.uid, { removed: false });
                            }}
                            className="cursor-pointer text-3xl text-gray-600"
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>

      <section className="flex justify-between">
        <button
          name="download_report"
          title="Download report"
          disabled={loadingReport}
          onClick={handleDownloadReport}
          className="border border rounded p-3 focus:outline-none"
        >
          {loadingReport ? "Generando reporte..." : "Descargar reporte"}
        </button>

        <Paginator
          itemsCount={companiesCount}
          limit={limit}
          setLimit={setLimit}
          page={page}
          setPage={setPage}
          initialLimit={8}
        />
      </section>
    </div>
  );
};

export default UsersTable;
