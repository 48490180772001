import { Link, use, useLocation } from "react-router-dom";
import { getReports } from "../api/reports";
import { useAppState } from "../contexts/app";
import AdicticLogo from "../assets/icons/Adictic.svg";
import React, { useCallback, useEffect, useState } from "react";
import { useWaitingCategoriesCount } from "../contexts/categories/hooks";
import { getSidebarStats } from "../api/audit";

const LinkItem = ({ page, location, counters, handleOnClick }) => (
  <li>
    <Link
      to={page.path}
      onClick={handleOnClick}
      className={
        "flex items-center justify-start p-4 no-underline tracking-wider text-lg" +
        (location.pathname === page.path
          ? " bg-gray-800 text-purple-300 border-l-4 border-purple-300"
          : " text-gray-500")
      }
    >
      <page.icon className="text-lg" />
      <span className="ml-6 px-1 w-32">{page.name}</span>
      {page.path === "/categories" && (
        <span className="ml-10">{counters.total_pending_categories}</span>
      )}
      {page.path === "/reports" && (
        <span className="ml-10">{counters.reports_count}</span>
      )}
      {page.path === "/requests" && (
        <span className="ml-10">{counters.total_request_count}</span>
      )}
      {page.path === "/skills" && (
        <span className="ml-5">{counters.requested_skills}</span>
      )}
    </Link>
  </li>
);

const Sidebar = ({ pages, location }) => {
  const appState = useAppState();
  const { pathname } = useLocation();
  const [counters, setCounters] = useState({
    reports_count: 0,
    requested_skills: 0,
    total_request_count: 0,
    total_pending_categories: 0,
  });

  const refreshCounts = useCallback(() => {
    getSidebarStats()
      .then((response) => setCounters(response))
      .catch((error) => console.log(`Error getting page counters`, error));
  }, [getSidebarStats]);

  useEffect(refreshCounts, [pathname]);
  return (
    <nav className="sidebar-container overflow-y-auto w-64 sm:relative bg-cover bg-no-repeat bg-top bg-gray-900  ">
      <div className="flex items-center pl-4 pt-8 mb-12">
        <img alt="Logo" className="h-5 w-5 mr-6 " src={AdicticLogo} />
        <p className="text-gray-500 text-xl font-bold tracking-wider">
          Adictic Admin
        </p>
      </div>
      <ul className="list-reset flex-1  ">
        {pages.slice(0, pages.length - 1).map((page, index) => (
          <LinkItem
            page={page}
            key={index}
            counters={counters}
            location={location}
            handleOnClick={() => appState.actions.setActualPage(page)}
          />
        ))}

        <div className="mt-16 mb-6 border-t border-gray-800" />

        <LinkItem
          handleOnClick={() =>
            appState.actions.setActualPage(pages[pages.length - 1])
          }
          page={pages[pages.length - 1]}
          location={location}
        />
      </ul>
    </nav>
  );
};

export default Sidebar;
