import React from 'react';
import { useForm } from 'react-hook-form';
import { VscClose, VscCheck } from 'react-icons/vsc';
import { useGlobalSkills, useSkill } from '../contexts/admin/hooks';
import Lottie from 'lottie-react-web';
import animation from '../assets/lottie/9914-loading-success-fail-spinner-stroke-update.json';

const SkillModal = () => {
  const {
    isSkillModalOpen,
    selectedSkill,
    skillModalMode,
    loading,
    actions: { toggleSkillModal, updateSkill, createSkill },
  } = useSkill();

  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    if (skillModalMode === 'edit') {
      updateSkill(selectedSkill.uid, data.englishName, data.spanishName);
    } else {
      createSkill(data.englishName, data.spanishName);
    }
  };
  return (
    <>
      {!!isSkillModalOpen ? (
        <div className="absolute w-screen h-screen bg-black bg-opacity-50 z-10 flex justify-center items-center">
          <div className="bg-white rounded-2xl pt-12 pb-6 relative px-20 flex flex-col items-center">
            <VscClose
              className=" text-gray-800 absolute top-0 right-0 text-4xl cursor-pointer mr-4 mt-4"
              onClick={toggleSkillModal}
            />

            <h1 className="text-gray-800 text-2xl mb-8">Habilidad</h1>

            <form
              className="flex items-center flex-col"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="border rounded w-90 px-4 py-3 mb-4">
                <p className="text-gray-500 text-sm">Nombre en inglés</p>
                <input
                  ref={register({ required: true })}
                  required
                  defaultValue={
                    skillModalMode === 'edit' ? selectedSkill.name : null
                  }
                  name="englishName"
                  className="text-gray-800 placeholder-gray-800 w-full outline-none"
                  placeholder="Escribe el nombre en inglés"
                  type="text"
                />
              </div>

              <div className="border rounded w-90 px-4 py-3">
                <p className="text-gray-500 text-sm">Nombre en español</p>
                <input
                  ref={register({ required: true })}
                  required
                  defaultValue={
                    skillModalMode === 'edit'
                      ? selectedSkill.locales.es.name
                      : null
                  }
                  name="spanishName"
                  className="text-gray-800 placeholder-gray-800 w-full outline-none"
                  placeholder="Escribe el nombre en español"
                  type="text"
                />
              </div>

              {!loading ? (
                <button
                  type="submit"
                  className="border rounded-md px-10 py-1 cursor-pointer mb-3 mt-6"
                  style={{ width: 'fit-content' }}
                >
                  <p className="text-blue-500 ">
                    {skillModalMode === 'edit' ? 'Actualizar' : 'Crear'}
                  </p>
                </button>
              ) : (
                <Lottie
                  width="10%"
                  height="10%"
                  options={{
                    animationData: animation,
                  }}
                />
              )}
            </form>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SkillModal;
