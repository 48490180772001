import _ from "lodash";
import React from "react";
import { FiSettings } from "react-icons/fi";
import { useGlobalSkills, useSkill } from "../../contexts/admin/hooks";

const Skills = () => {
  const {
    skills,
    error,
    loading,
    selectedSkill,
    requestedSkills,
  } = useGlobalSkills();
  const {
    actions: { selectSkill, toggleSkillModal },
  } = useSkill();
  return (
    <div className="h-full w-full">
      <div className="p-8  min-w-full bg-white shadow-md rounded-tr-lg rounded-b-lg h-full flex justify-between">
        <div
          className="rounded-lg bg-white shadow-lg border py-6"
          style={{ width: "49%" }}
        >
          <div className="px-8 my-2 flex justify-between ">
            <h3 className="text-xl font-bold">Habilidades</h3>
            <div
              onClick={() => toggleSkillModal("create")}
              className="px-4 py-2 bg-blue-500 rounded-md cursor-pointer"
            >
              <p className="text-white">Crear habilidad</p>
            </div>
          </div>

          <div className="py-6 flex-grow">
            {!!loading && null}
            {!loading &&
              _.values(skills)
                .reverse()
                .map((skill) => {
                  return (
                    <div
                      onClick={() => selectSkill(skill)}
                      key={`skill__${skill._id}`}
                      className={`px-8 flex flex-col cursor-pointer ${
                        !!selectedSkill && selectedSkill._id === skill._id
                          ? "bg-gray-200"
                          : ""
                      }`}
                    >
                      <div className="flex items-center  py-5">
                        <FiSettings className="mr-2 text-lg text-gray-700" />
                        <div
                          className={`w-1 h-1 mr-2 rounded-full bg-${
                            skill.removed === true ? "red" : "green"
                          }-400`}
                        />
                        <p className="text-lg text-gray-900">{skill.name}</p>
                      </div>
                      <div className="w-full h-1 border-b" />
                    </div>
                  );
                })}
          </div>
        </div>
        <div
          className="rounded-lg bg-white shadow-lg border py-6"
          style={{ width: "49%" }}
        >
          <div className="px-8 my-2 flex justify-between ">
            <h3 className="text-xl font-bold">Habilidades solicitadas</h3>
          </div>

          <div className="py-6 flex-grow">
            {!!loading && null}
            {!loading &&
              requestedSkills.map((skill) => {
                return !!skill.name ? (
                  <div
                    key={`requested_skill__${skill._id}`}
                    className={`px-8 flex flex-col `}
                  >
                    <div className="flex items-center  py-5">
                      <p className="text-lg text-gray-900">{skill.name}</p>
                    </div>
                    <div className="w-full h-1 border-b" />
                  </div>
                ) : null;
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
