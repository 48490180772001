import React from 'react';
import { useForm } from 'react-hook-form';
import AdicticLogo from '../../assets/icons/Adictic.svg';
import { useAuth } from '../../contexts/app/hooks';

const Login = () => {
  const { register, handleSubmit } = useForm();
  const authState = useAuth();

  const onSubmit = (data) => {
    authState.actions.login(data.email, data.password);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full">
        <div>
          <img
            className="mx-auto h-12 w-auto"
            src={AdicticLogo}
            alt="Adictic Logo"
          />
          <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
            Inicio de sesión
          </h2>
        </div>
        <form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm">
            <div>
              <input
                ref={register({ required: true })}
                aria-label="Correo Electronico"
                name="email"
                type="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-gray-600 focus:z-10 sm:text-sm sm:leading-5"
                placeholder="Correo Electronico"
              />
            </div>
            <div className="-mt-px">
              <input
                ref={register({ required: true })}
                aria-label="Contraseña"
                name="password"
                type="password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-gray-600 focus:z-10 sm:text-sm sm:leading-5"
                placeholder="Contraseña"
              />
            </div>
          </div>

          {authState.loginError && (
            <p className="text-red-600 mt-2">{authState.loginError.detail}</p>
          )}
          {/* <div className="mt-6 flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="remember_me"
                type="checkbox"
                className="form-checkbox h-4 w-4 text-gray-600 transition duration-150 ease-in-out"
              />
              <label
                for="remember_me"
                className="ml-2 block text-sm leading-5 text-gray-900"
              >
                Recuerdame
              </label>
            </div>
          </div> */}

          <div className="mt-6">
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-gray-500 hover:bg-gray-700 focus:outline-none focus:border-gray-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span>
              Iniciar sesión
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
