import shortUuid from "short-uuid";
import Lottie from "lottie-react-web";
import { REQUESTS_TABS } from "../../constants";
import React, { useEffect, useState } from "react";
import usePagination from "../../hooks/usePagination";
import { useUsersRequests } from "../../contexts/users/hooks";
import animation from "../../assets/lottie/9914-loading-success-fail-spinner-stroke-update.json";
import {
  Paginator,
  StoreRequestsTable,
  PageClaimRequestsTable,
  VerificationRequestsTable,
} from "../../components";

const Requests = () => {
  const [selectedTab, setSelectedTab] = useState(REQUESTS_TABS[0]);
  const [requestType, setRequestType] = useState(REQUESTS_TABS[0].type);
  const { page, limit, setLimit, setPage } = usePagination(null, 10);
  const {
    loading,
    storeRequests,
    requestsCount,
    pageClaimRequests,
    verificationRequests,
    actions: {
      updateUser,
      updateStoreRequest,
      updatePageClaimRequest,
      updateVerificationRequest,
    },
  } = useUsersRequests({
    page,
    limit,
    type: requestType,
  });

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    }
    if (limit !== 10) {
      setLimit(10);
    }
    setRequestType(selectedTab.type);
  }, [selectedTab]);

  return (
    <div className="h-full w-full box-border flex flex-col flex-grow">
      <div className="inline-flex w-full min-w-full">
        {REQUESTS_TABS.map((tab, index) => {
          return (
            <div
              key={`tab__${shortUuid.generate()}`}
              className={`p-4 rounded-t-lg mr-6 cursor-pointer${
                selectedTab.type === tab.type
                  ? " bg-white shadow-md"
                  : " bg-gray-400"
              }`}
              onClick={() => setSelectedTab(tab)}
            >
              <p className="tracking-wide text-center">{tab.title}</p>
            </div>
          );
        })}
      </div>

      <div className="h-full w-full bg-white flex flex-grow flex-col rounded-tr-lg rounded-b-lg shadow-md p-6 pt-8">
        <div className="w-full mb-4 flex-grow">
          {!!loading ? (
            <Lottie
              width="40%"
              options={{
                animationData: animation,
              }}
            />
          ) : requestType === "StoreRequests" ? (
            <StoreRequestsTable
              updateUser={updateUser}
              requests={storeRequests}
              updateStoreRequest={updateStoreRequest}
            />
          ) : requestType === "VerificationRequests" ? (
            <VerificationRequestsTable
              updateUser={updateUser}
              requests={verificationRequests}
              updateVerificationRequest={updateVerificationRequest}
            />
          ) : requestType === "PageClaimRequests" ? (
            <PageClaimRequestsTable
              requests={pageClaimRequests}
              updatePageClaimRequest={updatePageClaimRequest}
              updateUser={updateUser}
            />
          ) : null}
        </div>
        <Paginator
          itemsCount={requestsCount}
          limit={limit}
          setLimit={setLimit}
          page={page}
          setPage={setPage}
          initialLimit={10}
        />
      </div>
    </div>
  );
};

export default Requests;
