import _ from "lodash";
import { VscClose } from "react-icons/vsc";
import { useSpring, animated } from "react-spring";
import React, { useRef, useCallback, useEffect, useState } from "react";
import { useUsersState } from "../contexts/users";
import { useUser } from "../contexts/users/hooks";

const CustomModal = () => {
   const modalRef = useRef();
   const [reason, setReason] = useState("");
   const {
      actions: { updateUser },
   } = useUser();
   const {
      state: { showDisableModal, selectedUser },
      actions,
   } = useUsersState();

   const closeModal = useCallback(
      (e) => {
         if (modalRef.current === e.target) {
            actions.showDisableModal();
         }
      },
      [modalRef]
   );

   const keyPress = useCallback(
      (e) => {
         if (e.key === "Escape" && showDisableModal) {
            actions.showDisableModal();
         }
      },
      [actions, showDisableModal]
   );

   const handlePress = useCallback(() => {
      updateUser(selectedUser.username, {
         removed: true,
         reason,
         is_banned: true,
      });
      setReason("");
      actions.showDisableModal();
   }, [selectedUser, reason]);

   useEffect(() => {
      document.addEventListener("keydown", keyPress);
      return () => document.removeEventListener("keydown", keyPress);
   }, [keyPress]);

   useEffect(() => {
      if (!!selectedUser) {
         setReason(
            !!selectedUser.reason_disabled ? selectedUser.reason_disabled : ""
         );
      }
   }, [selectedUser]);

   const animation = useSpring({
      config: {
         duration: 250,
      },
      opacity: showDisableModal ? 1 : 0,
      transform: showDisableModal ? `translateY(0%)` : `translateY(-100%)`,
      width: "35%",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
      background: "white",
      borderRadius: "20px",
   });

   return (
      <React.Fragment>
         {!!showDisableModal && (
            <div
               onClick={closeModal}
               ref={modalRef}
               className="bg-black bg-opacity-50 z-10 flex items-center justify-center fixed left-0 bottom-0 w-full h-full"
            >
               <animated.div style={animation}>
                  <div className="rounded-2xl pt-5 pb-6">
                     <div className="flex items-end justify-end">
                        <VscClose
                           className=" text-gray-800 text-4xl cursor-pointer mr-4"
                           onClick={() => {
                              actions.showDisableModal();
                           }}
                        />
                     </div>
                     <div>
                        <h1 className="text-2xl uppercase text-center"></h1>
                        <div className="flex flex-col items-center tracking-wide mb-5 mt-8">
                           <img
                              className="w-20 h-20 rounded-full mb-4"
                              src={selectedUser.profile_photo}
                              alt={selectedUser.first_name}
                           />
                           <p className="text-2xl text-gray-800">{`${selectedUser.first_name} ${selectedUser.last_name}`}</p>
                        </div>
                        <div className="border focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1 mx-10 mb-5">
                           <div className="-mt-4 absolute tracking-wider px-1 uppercase text-xs">
                              <p>
                                 <label
                                    htmlFor="reason"
                                    className="bg-white text-gray-600 px-1"
                                 >
                                    Agregar motivo
                                 </label>
                              </p>
                           </div>
                           <textarea
                              id="reason"
                              autoComplete="false"
                              tabIndex="0"
                              type="text"
                              value={reason}
                              className="py-1 px-1 outline-none block h-full w-full"
                              onChange={(event) => {
                                 setReason(event.target.value);
                              }}
                           />
                        </div>

                        <div className="flex flex-col items-center tracking-wide">
                           <button
                              onClick={handlePress}
                              type="button"
                              className="border border-gray-200 bg-gray-200 text-gray-700 rounded-md px-4 py-2 m-2 transition duration-500 ease select-none hover:bg-gray-300 focus:outline-none focus:shadow-outline "
                           >
                              Guardar cambios
                           </button>
                        </div>
                     </div>
                  </div>
               </animated.div>
            </div>
         )}
      </React.Fragment>
   );
};

export default CustomModal;
