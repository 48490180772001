import qs from 'qs';
import Axios from 'axios';

const setAccessTokenForAxios = (accessToken) => {
  try {
    Axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  } catch (err) {
    console.log('Error setting headers ', err);
  }
};

const post = async (url, payload, config = {}) => {
  config = {
    ...config,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...config.headers,
    },
  };
  const response = await Axios.post(url, qs.stringify(payload), config);

  return response.data;
};

const put = async (url, payload, config = {}) => {
  config = {
    ...config,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...config.headers,
    },
  };
  const response = await Axios.put(url, qs.stringify(payload), config);

  return response.data;
};

const patch = async (url, payload, config = {}) => {
  config = {
    ...config,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...config.headers,
    },
  };
  const response = await Axios.patch(url, qs.stringify(payload), config);

  return response.data;
};

const deleteRequest = async (url, payload, config = {}) => {
  config = {
    data: {
      ...payload,
    },
  };
  const response = await Axios.delete(url, config);
  return response.data;
};

const get = async (url, ...args) => {
  const body = !args[0]
    ? ''
    : Object.keys(args[0])
        .map((key) => {
          return `${key}=${encodeURIComponent(args[0][key])}`;
        })
        .join('&');
  return (await Axios.get(`${url}?${body}`, ...args.slice(1, args.length)))
    .data;
};

export { post, get, setAccessTokenForAxios, deleteRequest, put, patch };
