import shortUuid from "short-uuid";
import Lottie from "lottie-react-web";
import React, { useEffect, useState } from "react";
import usePagination from "../../hooks/usePagination";
import { useReports } from "../../contexts/reports/hooks";
import { REPORTS_TABS, REPORTS_STATES } from "../../constants";
import animation from "../../assets/lottie/9914-loading-success-fail-spinner-stroke-update.json";
import {
  PostsGrid,
  Paginator,
  JobReportsTable,
  UsersReportsTable,
  EventReportsTable,
  CourseReportsTable,
  ProgramReportsTable,
  CompaniesReportsTable,
  CrowdfundingReportsTable,
} from "../../components";

const Reports = () => {
  const [selectedTab, setSelectedTab] = useState(REPORTS_TABS[0]);
  const [selectedState, setSelectedState] = useState(REPORTS_STATES[0]);
  const [selectedSubTab, setSelectedSubTab] = useState(
    REPORTS_TABS[4].subTabs[0].type
  );

  const [contentType, setContentType] = useState(REPORTS_TABS[0].type);
  const [state, setState] = useState(REPORTS_STATES[0].type);
  const { page, limit, setLimit, setPage } = usePagination();

  const {
    actions: { updateReportStatus },
    error,
    loading,
    reports,
    reportsCount,
  } = useReports({ page, limit, status: state, contentType });

  useEffect(() => {
    setSelectedState(REPORTS_STATES[0]);
    if (selectedTab.type !== "Training") {
      setContentType(selectedTab.type);
    } else {
      // Here we need to handle sub tabs
      setContentType(selectedSubTab);
    }
  }, [selectedTab, selectedSubTab]);

  useEffect(() => {
    setState(selectedState.type);
  }, [selectedState]);

  return (
    <div className="h-full w-full box-border flex flex-col flex-grow">
      <div className="inline-flex w-full min-w-full">
        {REPORTS_TABS.map((tab, index) => {
          return (
            <div
              key={`tab__${shortUuid.generate()}`}
              className={`p-4 w-40 rounded-t-lg mr-6 cursor-pointer${
                selectedTab.type === tab.type
                  ? " bg-white shadow-md"
                  : " bg-gray-400"
              }`}
              onClick={() => setSelectedTab(tab)}
            >
              <p className="tracking-wide text-center">{tab.title}</p>
            </div>
          );
        })}
      </div>

      <div className="h-full w-full bg-white flex flex-grow flex-col rounded-tr-lg rounded-b-lg shadow-md p-6 pt-8">
        {selectedTab.type === "Training" && (
          <div className="mb-4 flex" style={{ width: "fit-content" }}>
            {selectedTab.subTabs.map((subTabItem, index) => {
              return (
                <div className="flex flex-col items-center">
                  <div
                    key={`subTab__${shortUuid.generate()}`}
                    className={`inline-block px-4 py-2 w-40  cursor-pointer${
                      selectedSubTab === subTabItem.type ? " " : ""
                    }`}
                    onClick={() => setSelectedSubTab(subTabItem.type)}
                  >
                    <p
                      className={`tracking-wide text-center${
                        selectedSubTab === subTabItem.type ? " font-bold" : ""
                      }`}
                    >
                      {subTabItem.title}
                    </p>
                  </div>
                  <div
                    className={
                      selectedSubTab === subTabItem.type
                        ? "w-12 h-px rounded-lg bg-gray-900"
                        : "hidden"
                    }
                  />
                </div>
              );
            })}
          </div>
        )}

        <div
          className="bg-gray-300 rounded-full mb-4"
          style={{ width: "fit-content" }}
        >
          {REPORTS_STATES.map((stateItem, index) => {
            return (
              <div
                key={`state__${shortUuid.generate()}`}
                className={`inline-block p-4 w-40 rounded-full cursor-pointer${
                  selectedState.type === stateItem.type
                    ? " bg-white shadow-xs border"
                    : ""
                }`}
                onClick={() => setSelectedState(stateItem)}
              >
                <p className="tracking-wide text-center">{stateItem.title}</p>
              </div>
            );
          })}
        </div>
        <div className="w-full mb-4 flex-grow">
          {loading ? (
            <Lottie
              width="40%"
              options={{
                animationData: animation,
              }}
            />
          ) : contentType === "Post" ? (
            <PostsGrid
              reports={reports}
              handleUpdateReportStatus={updateReportStatus}
            />
          ) : contentType === "User" ? (
            <UsersReportsTable
              reports={reports}
              handleUpdateReportStatus={updateReportStatus}
            />
          ) : contentType === "Event" ? (
            <EventReportsTable
              reports={reports}
              handleUpdateReportStatus={updateReportStatus}
            />
          ) : contentType === "CrowdfundingCampaign" ? (
            <CrowdfundingReportsTable
              reports={reports}
              handleUpdateReportStatus={updateReportStatus}
            />
          ) : contentType === "Program" ? (
            <ProgramReportsTable
              reports={reports}
              handleUpdateReportStatus={updateReportStatus}
            />
          ) : contentType === "Course" ? (
            <CourseReportsTable
              reports={reports}
              handleUpdateReportStatus={updateReportStatus}
            />
          ) : contentType === "Job" ? (
            <JobReportsTable
              reports={reports}
              handleUpdateReportStatus={updateReportStatus}
            />
          ) : contentType === "Company" ? (
            <CompaniesReportsTable
              reports={reports}
              handleUpdateReportStatus={updateReportStatus}
            />
          ) : null}
        </div>
        <Paginator
          page={page}
          limit={limit}
          initialLimit={8}
          setPage={setPage}
          setLimit={setLimit}
          itemsCount={reportsCount}
        />
      </div>
    </div>
  );
};

export default Reports;
