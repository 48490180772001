export const ActionTypes = {
  SET_JOBS: "SET_JOBS",
  SET_EVENTS: "SET_EVENTS",
  SET_COURSES: "SET_COURSES",
  SET_PROGRAMS: "SET_PROGRAMS",
  SET_CAMPAIGNS: "SET_CAMPAIGNS",
  SET_CATEGORIES_DATA: "SET_CATEGORIES_DATA",
  UPDATE_CATEGORY_ITEM: "UPDATE_CATEGORY_ITEM",
  SET_SELECTED_CATEGORY_ITEM: "SET_SELECTED_CATEGORY_ITEM",
  TOGGLE_USER_MODAL: 'TOGGLE_CATEGORIES_MODAL',
};
