import { ActionTypes } from "./types";

const CategoriesActions = (dispatch) => {
  return {
    setJobs: (jobs) => {
      dispatch({ type: ActionTypes.SET_JOBS, jobs });
    },
    setEvents: (events) => {
      dispatch({ type: ActionTypes.SET_EVENTS, events });
    },
    setCourses: (courses) => {
      dispatch({ type: ActionTypes.SET_COURSES, courses });
    },
    setPrograms: (programs) => {
      dispatch({ type: ActionTypes.SET_PROGRAMS, programs });
    },
    setCampaigns: (campaigns) => {
      dispatch({ type: ActionTypes.SET_CAMPAIGNS, campaigns });
    },
    setCategoriesData: ({
      jobs,
      events,
      courses,
      programs,
      campaigns,
      jobsCount,
      eventsCount,
      coursesCount,
      programsCount,
      campaignsCount,
    }) => {
      dispatch({
        jobsCount,
        eventsCount,
        coursesCount,
        programsCount,
        campaignsCount,
        jobs,
        events,
        courses,
        programs,
        campaigns,
        type: ActionTypes.SET_CATEGORIES_DATA,
      });
    },
    updateCategoryItem: ({ store, item }) => {
      dispatch({ type: ActionTypes.UPDATE_CATEGORY_ITEM, store, item });
    },
    setSelectedCategoryItem: ({ selectedCategoryItem }) => {
      dispatch({
        type: ActionTypes.SET_SELECTED_CATEGORY_ITEM,
        selectedCategoryItem,
      });
    },
    toggleCategoriesModal: () => {
      dispatch({ type: ActionTypes.TOGGLE_USER_MODAL });
    },
  };
};

export default CategoriesActions;
