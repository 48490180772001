import { useCallback, useEffect, useState } from 'react';
import { uploadImage } from '../../api/cloudinary';
import { useCompaniesState } from '.';
import {
  getGlobalCompanyPages,
  updateCompany as updateCompanyAPI,
} from '../../api/company';

export const useCompanyPages = ({ page, limit, name }) => {
  const {
    state: { companies },
    actions: { setCompanies },
  } = useCompaniesState();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [companiesCount, setCompaniesCount] = useState(0);

  const fetchCompanyPages = useCallback(async () => {
    if (!loading) {
      setLoading(true);
    }
    try {
      const response = await getGlobalCompanyPages({ page, limit, name });
      setCompanies(response.result.companies);
      setLoading(false);
      setCompaniesCount(response.result.count);
    } catch (error) {
      setLoading(false);
      setError(error.response.data);
    }
  }, [companies, page, limit, name]);

  useEffect(() => {
    fetchCompanyPages();
  }, [page, limit, name]);

  return {
    companies,
    companiesCount,
    error,
    loading,
    actions: {},
  };
};

export const useCompanyPage = () => {
  const {
    state: { isCompaniesModalOpen, selectedCompany },
    actions: {
      updateCompany: updateCompanyInState,
      toggleCompanyModal,
      setSelectedCompany,
    },
  } = useCompaniesState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [uploadingImage, setUploadingImage] = useState(false);

  const selectCompany = async (company) => {
    if (!loading) {
      setLoading(true);
    }
    try {
      setSelectedCompany(company);
      toggleCompanyModal();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.response.data);
    }
  };

  const updateCompany = async (companyUid, payload) => {
    try {
      const response = await updateCompanyAPI(companyUid, payload);
      updateCompanyInState(response.company);
      if (!!selectedCompany && selectedCompany.uid === companyUid) {
        setSelectedCompany(response.company);
      }
      return response.company;
    } catch (error) {
      setError(error.response.data);
    }
  };

  const updateImage = async (companyUid, file) => {
    setUploadingImage(true);
    try {
      const newImage = await uploadImage(file, 'company');
      const updatedCompany = await updateCompany(companyUid, {
        photoUrl: newImage.url,
      });
      setUploadingImage(false);

      return updatedCompany.company;
    } catch (error) {
      setUploadingImage(false);
    }
  };

  return {
    isCompaniesModalOpen,
    loading,
    uploadingImage,
    company: selectedCompany,
    actions: {
      selectCompany,
      updateCompany,
      toggleCompanyModal,
      updateImage,
    },
  };
};
