import {
  get as getAxios,
  // post as postAxios,
  patch as patchAxios,
} from '../utils/requests-handlers';

const { REACT_APP_API_URL } = process.env;

export function getStoreRequests(payload = { page: 1, limit: 10 }) {
  return getAxios(`${REACT_APP_API_URL}/v1/admin/requests/store`, payload);
}

export function updateStoreRequest(id, payload) {
  return patchAxios(
    `${REACT_APP_API_URL}/v1/admin/requests/store/${id}`,
    payload // status: verifying, approved, rejected
  );
}

export function getPageClaimRequests(payload = { page: 1, limit: 10 }) {
  return getAxios(`${REACT_APP_API_URL}/v1/admin/requests/page`, payload);
}

export function updatePageClaimRequest(id, payload) {
  return patchAxios(
    `${REACT_APP_API_URL}/v1/admin/requests/page/${id}`,
    payload // status: verifying, approved, rejected
  );
}

export function getGlobalCompanyPages(
  payload = { page: 1, limit: 10, name: '' }
) {
  return getAxios(`${REACT_APP_API_URL}/v1/admin/companies`, payload);
}

export function updateCompany(companyUid, payload) {
  return patchAxios(
    `${REACT_APP_API_URL}/v1/admin/companies/${companyUid}`,
    payload // name || photoUrl || sectorUid || professionUid
  );
}
