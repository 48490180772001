import { useState } from 'react';

const usePagination = (initialPage = 1, initialLimit = 8) => {
  const [page, setPage] = useState(initialPage);
  const [limit, setLimit] = useState(initialLimit);
  return {
    page,
    setPage,
    limit,
    setLimit,
  };
};

export default usePagination;
