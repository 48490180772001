import { ActionTypes } from "./types";

const UsersActions = (dispatch) => {
  return {
    setLevels: (levels) => {
      dispatch({ type: ActionTypes.SET_LEVELS, levels });
    },
    setSkills: (skills) => {
      dispatch({ type: ActionTypes.SET_SKILLS, skills });
    },
    updateSkill: (skill) => {
      dispatch({ type: ActionTypes.UPDATE_SKILL, skill });
    },
    setSelectedSkill: (skill) => {
      dispatch({ type: ActionTypes.SET_SELECTED_SKILL, skill });
    },
    toggleSkillModal: (skillModalMode) => {
      dispatch({ type: ActionTypes.TOGGLE_SKILL_MODAL, skillModalMode });
    },
    setSectors: (sectors) => {
      dispatch({ type: ActionTypes.SET_SECTORS, sectors });
    },
    updateSector: (sector) => {
      dispatch({ type: ActionTypes.UPDATE_SECTOR, sector });
    },
    setSelectedSector: (sector) => {
      dispatch({ type: ActionTypes.SET_SELECTED_SECTOR, sector });
    },
    toggleSectorModal: (sectorModalMode) => {
      dispatch({ type: ActionTypes.TOGGLE_SECTOR_MODAL, sectorModalMode });
    },
    setProfessions: (professions) => {
      dispatch({ type: ActionTypes.SET_PROFESSIONS, professions });
    },
    updateProfession: (profession) => {
      dispatch({ type: ActionTypes.UPDATE_PROFESSION, profession });
    },
    setSelectedProfession: (profession) => {
      dispatch({ type: ActionTypes.SET_SELECTED_PROFESSION, profession });
    },
    toggleProfessionModal: (professionModalMode) => {
      dispatch({
        type: ActionTypes.TOGGLE_PROFESSION_MODAL,
        professionModalMode,
      });
    },
  };
};

export default UsersActions;
