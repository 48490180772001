import { ActionTypes } from './types';

export const INITIAL_STATE = {
  reports: {},
  selectedReport: null,
  selectedReportReporters: [],
  isReportModalOpen: false,
};

const reducerMap = {
  [ActionTypes.SET_REPORTS]: (state, { reports }) => {
    return {
      ...state,
      reports: reports.reduce((acc, report) => {
        if (report._id) {
          acc[report._id] = report;
        }
        return acc;
      }, {}),
    };
  },
  [ActionTypes.UPDATE_REPORT]: (state, { report }) => {
    state.reports[report._id] = report;
    return { ...state };
  },
  [ActionTypes.SET_SELECTED_REPORT]: (state, { report }) => {
    return { ...state, selectedReport: report };
  },
  [ActionTypes.SET_SELECTED_REPORT_REPORTERS]: (state, { reporters }) => {
    return { ...state, selectedReportReporters: reporters };
  },
  [ActionTypes.TOGGLE_REPORT_MODAL]: (state) => {
    return { ...state, isReportModalOpen: !state.isReportModalOpen };
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  return action.type in reducerMap
    ? reducerMap[action.type]({ ...state }, action)
    : state;
};

export default reducer;
