import _ from "lodash";
import React from "react";
import moment from "moment";
import shortUuid from "short-uuid";
import { useAppState } from "../contexts/app";
import { FiCheck, FiX } from "react-icons/fi";
import { useUser } from "../contexts/users/hooks";
import { VscCircleSlash, VscCheck } from "react-icons/vsc";

const VerificationRequestsTable = ({
  requests,
  updateUser,
  updateVerificationRequest,
}) => {
  const appState = useAppState();
  const {
    actions: { selectUser },
  } = useUser();
  return (
    <div className="py-6 overflow-x-auto flex-grow">
      <div className="inline-block w-full overflow-hidden">
        <table className="w-full min-w-full leading-normal table-fixed">
          <thead>
            <tr>
              <th className="w-1/5 px-5 py-3 text-left text-xs font-semibold text-gray-600 tracking-wider">
                Nombre
              </th>
              <th className="w-1/5 px-5 py-3 text-left text-xs font-semibold text-gray-600 tracking-wider">
                Email/Teléfono
              </th>
              <th className="w-1/6 px-5 py-3 text-left text-xs font-semibold text-gray-600 tracking-wider">
                Empresa
              </th>
              <th className="w-1/6 px-5 py-3 text-left text-xs font-semibold text-gray-600 tracking-wider">
                Solicitud
              </th>
              <th className="w-2/12 px-5 py-3 text-left text-xs font-semibold text-gray-600 tracking-wider">
                Verificación
              </th>
              <th className="w-2/12 px-5 py-3 text-left text-xs font-semibold text-gray-600 tracking-wider">
                Documento
              </th>
              <th className="w-1/12 px-2 py-3 text-left text-xs font-semibold text-gray-600 tracking-wider"></th>
            </tr>
          </thead>
          <tbody>
            {_.values(requests).map(
              ({ user, created_at, status, _id, medias }) => {
                return (
                  <tr
                    key={`store_request__${shortUuid.generate()}`}
                    className={`${
                      !!user.removed ? "text-gray-500" : "text-gray-900"
                    }`}
                  >
                    <td className="px-5 py-5 border-b border-gray-200">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 w-10 h-10">
                          <img
                            className={`w-full h-full rounded-full ${
                              !!user.removed && "opacity-50"
                            }`}
                            src={user.profile_photo}
                            alt={user.first_name}
                          />
                        </div>
                        <div className="ml-3">
                          <p className="break-all ">
                            {`${user.first_name} ${user.last_name}`}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200">
                      <p className="break-all">
                        {!!user.number && typeof user.number === "object"
                          ? user.number.data.number
                          : user.email}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200">
                      <p className="break-all ">{user.current_company}</p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200">
                      <p className="break-all">
                        {_.upperFirst(moment(created_at).fromNow())}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200">
                      <div className="flex items-center">
                        {status === "pending" ? (
                          <>
                            <div className="border-2 border-gray-200 rounded-full">
                              <FiX
                                onClick={() =>
                                  updateVerificationRequest(_id, {
                                    status: "declined",
                                  })
                                }
                                className="cursor-pointer text-red-600 m-2 text-lg"
                              />
                            </div>
                            <div className="ml-3 border-2 border-gray-200 rounded-full">
                              <FiCheck
                                onClick={() =>
                                  updateVerificationRequest(_id, {
                                    status: "approved",
                                  })
                                }
                                className="cursor-pointer text-blue-500 m-2 text-lg"
                              />
                            </div>
                          </>
                        ) : status === "approved" ? (
                          <div className="border rounded-full border-gray-200">
                            <p className="m-1 mx-3 text-blue-500">Aprobado</p>
                          </div>
                        ) : (
                          <div className="border rounded-full border-gray-200">
                            <p className="m-1 mx-3 text-red-600">Denegado</p>
                          </div>
                        )}
                      </div>
                    </td>

                    <td className="px-5 py-5 border-b border-gray-200">
                      <a href={medias[0]} target="_blank">
                        Anverso
                      </a>
                      <br />
                      <a href={medias[1]} target="_blank">
                        Reverso
                      </a>
                    </td>

                    {appState.state.user.username !== user.username && (
                      <td className="px-5 py-5 border-b border-gray-200">
                        {!user.removed ? (
                          <VscCircleSlash
                            onClick={() => {
                              updateUser(
                                "VerificationRequest",
                                _id,
                                user.username,
                                {
                                  removed: true,
                                }
                              );
                            }}
                            className="cursor-pointer text-3xl text-gray-600"
                          />
                        ) : (
                          <VscCheck
                            onClick={() => {
                              updateUser(
                                "VerificationRequest",
                                _id,
                                user.username,
                                {
                                  removed: false,
                                }
                              );
                            }}
                            className="cursor-pointer text-3xl text-gray-600"
                          />
                        )}
                      </td>
                    )}
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default VerificationRequestsTable;
