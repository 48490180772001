import _ from "lodash";
import shortUuid from "short-uuid";
import { FiEdit2 } from "react-icons/fi";
import { VscClose } from "react-icons/vsc";
import { useUser } from "../contexts/users/hooks";
import React, { useEffect, useState } from "react";
import { useLevels } from "../contexts/admin/hooks";

const UserDetailModal = () => {
  const {
    user,
    isUserModalOpen,
    isPasswordChanged,
    actions: { toggleUserModal, updateUser, changePassword },
  } = useUser();

  const {
    data: { levels },
  } = useLevels();

  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [blackList, setBlackList] = useState(false);

  const LEVELS_ARRAY =
    [
      ..._.values(levels),
      {
        index: 4,
        uid: "verified",
        name: "Verificado",
        url:
          "https://res.cloudinary.com/adictic/image/upload/v1606514251/levels/Ambassador_3x_uvcz70.png",
        color: "#4EF5D3",
      },
    ] ?? [];

  useEffect(() => {
    if (!!user) {
      setBlackList(user.discover_blacklist);
      if (isPasswordChanged) {
        setNewPassword("");
        setIsChangingPassword(false);
      }
      return () => {
        setBlackList(false);
        setIsChangingPassword(false);
        setNewPassword("");
      };
    }
  }, [user]);

  return (
    <>
      {!!isUserModalOpen && (
        <div className="absolute w-screen h-screen bg-black bg-opacity-50 z-10 flex justify-center items-center">
          <div className="bg-white rounded-2xl pt-12 pb-6 relative px-20 flex flex-col items-center">
            <VscClose
              className=" text-gray-800 absolute top-0 right-0 text-4xl cursor-pointer mr-4 mt-4"
              onClick={toggleUserModal}
            />
            <div className="w-64 flex flex-col items-center tracking-wide mb-5">
              <img
                className="w-20 h-20 rounded-full mb-4"
                src={user.profile_photo}
                alt={user.username}
              />
              <p className="text-2xl text-gray-800">
                {user.is_completed
                  ? `${user.first_name} ${user.last_name}`
                  : "Usuario nuevo"}
              </p>
              <p className="text-base text-gray-600 mb-2 tracking-wide">
                {user.email}
              </p>
              <div className="inline-flex items-center">
                <img
                  className="w-8 h-8 mr-2"
                  src={
                    !!user.verified ? _.last(LEVELS_ARRAY).url : user.level.url
                  }
                  alt={user.level.name}
                />
                <div className="inline-block relative">
                  <select
                    onChange={(event) => {
                      const value = event.target.value;
                      if (user.level.index !== value) {
                        updateUser(user.username, {
                          level: value,
                        });
                      }
                    }}
                    value={!!user.verified ? 4 : user.level.index}
                    className="block cursor-pointer appearance-none w-36 leading-tight text-gray-600 tracking-wide focus:outline-none"
                  >
                    {LEVELS_ARRAY.map((level) => {
                      return (
                        <option
                          key={`table_limit__${shortUuid.generate()}`}
                          value={level.index}
                        >
                          {level.name}
                        </option>
                      );
                    })}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <div className="border rounded-full p-2 cursor-pointer">
                      <FiEdit2 className="cursor-pointer text-sm" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="inline-flex items-center">
                <div className="relative flex-col">
                  <p className="text-base text-gray-600 mb-2 tracking-wide">
                    Discover Blacklist
                  </p>
                </div>
                <div className="flex ml-10 flex-col">
                  <input
                    type="checkbox"
                    name="toggle"
                    id="toggle"
                    onChange={(event) => {
                      setBlackList(event.target.checked);
                      updateUser(user.username, {
                        discover_blacklist: event.target.checked,
                      });
                    }}
                    checked={blackList}
                    class="toggle-checkbox block w-6 h-6 rounded-full bg-white border-4 cursor-pointer"
                  />{" "}
                </div>
              </div>
            </div>
            <div>
              {!isChangingPassword ? (
                <button
                  className="outline p-3"
                  onClick={() => setIsChangingPassword(!isChangingPassword)}
                >
                  Cambiar contraseña
                </button>
              ) : (
                <>
                  <input
                    type="search"
                    placeholder="Nueva contraseña"
                    className="bg-purple-white shadow rounded border-0 p-3"
                    onChange={(text) => {
                      setNewPassword(text.target.value);
                    }}
                  />
                  <button
                    className="ml-2 p-3"
                    onClick={() => {
                      changePassword({
                        username: user.username,
                        newPassword,
                      });
                    }}
                  >
                    Guardar
                  </button>
                </>
              )}
            </div>

            <div className="flex flex-col w-80">
              {user.companies.map((company, index) => {
                return (
                  <div
                    key={`user_company__${shortUuid.generate()}`}
                    className={`inline-flex items-center relative py-4 ${
                      index !== user.companies.length - 1 && "border-b "
                    }`}
                  >
                    <img
                      alt={company.name}
                      src={company.photo}
                      className="w-8 h-8 mr-2 rounded-full object-scale-down"
                    />
                    <p>{company.name}</p>
                    <div className=" absolute right-0 border rounded-full px-4 py-1 cursor-pointer">
                      <a href={company.link} className="text-blue-500 ">
                        ir a página
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>

            {/* <div
              onClick={toggleUserModal}
              className="border rounded-md px-8 py-1 cursor-pointer mb-3"
            >
              <p className="text-blue-500 ">Aceptar</p>
            </div>
            <div
              onClick={() => {
                updateUser(user.username, {
                  removed: !!user.removed ? false : true,
                });
              }}
              className="inline-flex items-center"
            >
              {!user.removed ? (
                <VscCircleSlash className="text-gray-500 mr-2 text-2xl cursor-pointer" />
              ) : (
                <VscCheck className="text-gray-500 mr-2 text-2xl cursor-pointer" />
              )}

              <p className="cursor-pointer">
                {!user.removed ? 'Bloquear' : 'Desbloquear'}
              </p>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default UserDetailModal;
