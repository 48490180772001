import { get as getAxios } from "../utils/requests-handlers";

const { REACT_APP_API_URL } = process.env;

export function getNotificationsLog() {
  return getAxios(`${REACT_APP_API_URL}/v1/admin/audit/notifications`);
}

export function getUsersReport() {
  return getAxios(`${REACT_APP_API_URL}/v1/admin/audit/reports/users`);
}

export function getCompaniesReport() {
  return getAxios(`${REACT_APP_API_URL}/v1/admin/audit/reports/companies`);
}

export function getSidebarStats() {
  return getAxios(`${REACT_APP_API_URL}/v1/admin/stats`);
}
