import { ActionTypes } from "./types";

export const INITIAL_STATE = {
  skills: {},
  levels: {},
  sectors: {},
  professions: {},
  skillModalMode: "",
  sectorModalMode: "",
  selectedSkill: null,
  selectedSector: null,
  isSkillModalOpen: false,
  professionModalMode: "",
  selectedProfession: null,
  isSectorModalOpen: false,
  isProfessionModalOpen: false,
};

const reducerMap = {
  [ActionTypes.SET_LEVELS]: (state, { levels }) => {
    return {
      ...state,
      levels: levels.reduce((acc, _level) => {
        if (_level._id) {
          acc[_level._id] = _level;
        }
        return acc;
      }, {}),
    };
  },
  [ActionTypes.SET_SKILLS]: (state, { skills }) => {
    return {
      ...state,
      skills: skills.reduce((acc, skill) => {
        if (skill._id) {
          acc[skill._id] = skill;
        }
        return acc;
      }, {}),
    };
  },
  [ActionTypes.UPDATE_SKILL]: (state, { skill }) => {
    state.skills[skill._id] = skill;
    return { ...state };
  },

  [ActionTypes.SET_SELECTED_SKILL]: (state, { skill }) => {
    return { ...state, selectedSkill: skill };
  },
  [ActionTypes.TOGGLE_SKILL_MODAL]: (state, { skillModalMode }) => {
    return {
      ...state,
      isSkillModalOpen: !state.isSkillModalOpen,
      skillModalMode: !state.isSkillModalOpen === false ? null : skillModalMode,
    };
  },
  [ActionTypes.SET_SECTORS]: (state, { sectors }) => {
    return {
      ...state,
      sectors: sectors.reduce((acc, sector) => {
        if (sector._id) {
          acc[sector._id] = sector;
        }
        return acc;
      }, {}),
    };
  },
  [ActionTypes.UPDATE_SECTOR]: (state, { sector }) => {
    state.sectors[sector._id] = sector;
    return { ...state };
  },

  [ActionTypes.SET_SELECTED_SECTOR]: (state, { sector }) => {
    return { ...state, selectedSector: sector };
  },
  [ActionTypes.TOGGLE_SECTOR_MODAL]: (state, { sectorModalMode }) => {
    return {
      ...state,
      isSectorModalOpen: !state.isSectorModalOpen,
      sectorModalMode:
        !state.isSectorModalOpen === false ? null : sectorModalMode,
    };
  },
  [ActionTypes.SET_PROFESSIONS]: (state, { professions }) => {
    return {
      ...state,
      professions: professions.reduce((acc, profession) => {
        if (profession._id) {
          acc[profession._id] = profession;
        }
        return acc;
      }, {}),
    };
  },
  [ActionTypes.UPDATE_PROFESSION]: (state, { profession }) => {
    state.professions[profession._id] = profession;
    return { ...state };
  },

  [ActionTypes.SET_SELECTED_PROFESSION]: (state, { profession }) => {
    return { ...state, selectedProfession: profession };
  },
  [ActionTypes.TOGGLE_PROFESSION_MODAL]: (state, { professionModalMode }) => {
    return {
      ...state,
      isProfessionModalOpen: !state.isProfessionModalOpen,
      professionModalMode:
        !state.isProfessionModalOpen === false ? null : professionModalMode,
    };
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  return action.type in reducerMap
    ? reducerMap[action.type]({ ...state }, action)
    : state;
};

export default reducer;
