import { ActionTypes } from './types';

const ReportsActions = (dispatch) => {
  return {
    setReports: (reports) => {
      dispatch({ type: ActionTypes.SET_REPORTS, reports });
    },
    updateReport: (report) => {
      dispatch({ type: ActionTypes.UPDATE_REPORT, report });
    },
    setSelectedReport: (report) => {
      dispatch({ type: ActionTypes.SET_SELECTED_REPORT, report });
    },
    setSelectedReportReporters: (reporters) => {
      dispatch({ type: ActionTypes.SET_SELECTED_REPORT_REPORTERS, reporters });
    },
    toggleReportModal: () => {
      dispatch({ type: ActionTypes.TOGGLE_REPORT_MODAL });
    },
  };
};

export default ReportsActions;
