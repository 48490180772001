import { ActionTypes } from "./types";

const UsersActions = (dispatch) => {
   return {
      setUsers: (users) => {
         dispatch({ type: ActionTypes.SET_USERS, users });
      },
      updateUser: (user) => {
         dispatch({ type: ActionTypes.UPDATE_USER, user });
      },
      setVerificationRequests: (requests) => {
         dispatch({ type: ActionTypes.SET_VERIFICATION_REQUESTS, requests });
      },
      updateVerificationRequest: (request) => {
         dispatch({ type: ActionTypes.UPDATE_VERIFICATION_REQUESTS, request });
      },
      setStoreRequests: (requests) => {
         dispatch({ type: ActionTypes.SET_STORE_REQUESTS, requests });
      },
      updateStoreRequest: (request) => {
         dispatch({ type: ActionTypes.UPDATE_STORE_REQUESTS, request });
      },
      setPageClaimRequests: (requests) => {
         dispatch({ type: ActionTypes.SET_PAGE_CLAIM_REQUESTS, requests });
      },
      updatePageClaimRequest: (request) => {
         dispatch({ type: ActionTypes.UPDATE_PAGE_CLAIM_REQUEST, request });
      },
      setUser: (user) => {
         dispatch({ type: ActionTypes.SET_SELECTED_USER, user });
      },
      toggleUserModal: () => {
         dispatch({ type: ActionTypes.TOGGLE_USER_MODAL });
      },
      showDisableModal: () => {
         dispatch({ type: ActionTypes.SHOW_DISABLE_MODAL });
      },
   };
};

export default UsersActions;
