import {
  get as getAxios,
  // post as postAxios,
  patch as patchAxios,
} from '../utils/requests-handlers';

const { REACT_APP_API_URL } = process.env;

export function getReports(
  payload = {
    page: 1,
    limit: 10,
    contentType: 'Post',
    status: 'pending',
  }
) {
  return getAxios(`${REACT_APP_API_URL}/v1/reports`, payload);
}
export function getReporters(reportId) {
  return getAxios(`${REACT_APP_API_URL}/v1/reports/${reportId}/reporters`);
}

export function updateReport(
  reportId,
  payload = {
    status: 'blocked',
  }
) {
  return patchAxios(`${REACT_APP_API_URL}/v1/reports/${reportId}`, payload);
}
