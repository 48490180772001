import React, { useState } from 'react';
import {
  useGlobalProfessions,
  useGlobalSectors,
  useProfession,
  useSector,
} from '../../contexts/admin/hooks';
import { FiSettings, FiChevronRight } from 'react-icons/fi';
import _ from 'lodash';
import shortUuid from 'short-uuid';
import { SECTORS_TABS } from '../../constants';
const SectorsAndProfessions = () => {
  const [selectedTab, setSelectedTab] = useState(SECTORS_TABS[0]);
  const { sectors, error, loading, selectedSector } = useGlobalSectors({
    category: selectedTab.type,
  });
  const {
    professions,
    error: professionsError,
    loading: loadingProfessions,
    selectedProfession,
  } = useGlobalProfessions({
    sectorUid: !!selectedSector ? selectedSector.uid : '',
  });
  const {
    actions: { selectSector, toggleSectorModal },
  } = useSector();
  const {
    actions: { selectProfession, toggleProfessionModal },
  } = useProfession();
  return (
    <div className="h-full w-full">
      <div className="inline-flex w-full min-w-full">
        {SECTORS_TABS.map((tab, index) => {
          return (
            <div
              key={`tab__${shortUuid.generate()}`}
              className={`p-4 rounded-t-lg mr-6 cursor-pointer${
                selectedTab.type === tab.type ? ' bg-white' : ' bg-gray-400'
              }`}
              style={{ width: 'fit-content' }}
              onClick={() => setSelectedTab(tab)}
            >
              <p className="tracking-wide text-center">{tab.title}</p>
            </div>
          );
        })}
      </div>
      <div className="p-8  min-w-full bg-white shadow-md rounded-tr-lg rounded-b-lg h-full flex justify-between">
        <div
          className="rounded-lg bg-white shadow-lg border py-6"
          style={{ width: '49%' }}
        >
          <div className="px-8 mb-2 flex justify-between items-center">
            <h3 className="text-xl font-bold">Sectores</h3>
            <div
              onClick={() => toggleSectorModal('create')}
              className="px-4 py-2 bg-blue-500 rounded-md cursor-pointer"
            >
              <p className="text-white">Crear sector</p>
            </div>
          </div>
          <div className="py-6 flex-grow">
            {!!loading && null}
            {!loading &&
              _.values(sectors)
                .reverse()
                .map((sector) => {
                  return (
                    <div
                      onClick={() => selectSector(sector)}
                      key={`sector__${sector._id}`}
                      className={`px-8 flex flex-col cursor-pointer ${
                        !!selectedSector && selectedSector._id === sector._id
                          ? 'bg-gray-200'
                          : ''
                      }`}
                    >
                      <div className="flex items-center relative py-5">
                        <FiSettings className="mr-2 text-lg text-gray-700" />
                        <div
                          className={`w-1 h-1 mr-2 rounded-full bg-${
                            sector.removed === true ? 'red' : 'green'
                          }-400`}
                        />
                        <p className="text-lg text-gray-900">{sector.name}</p>
                        <div
                          className={`absolute right-0 p-1 rounded-full ${
                            !!selectedSector &&
                            selectedSector._id === sector._id
                              ? ' bg-blue-500 text-white'
                              : ' border border-blue-500 text-blue-500'
                          }`}
                        >
                          <FiChevronRight />
                        </div>
                      </div>
                      <div className="w-full h-1 border-b" />
                    </div>
                  );
                })}
          </div>
        </div>
        <div
          style={{ width: '49%' }}
          className="rounded-lg bg-white shadow-lg border py-6"
        >
          <div className="px-8 mb-2 flex justify-between items-center">
            <div className="flex items-center">
              <h3 className="text-xl font-bold">Profesiones</h3>
              {!!selectedSector && (
                <div className="flex items-center">
                  <FiChevronRight className="mx-2" />
                  <p className="text-xl ">{selectedSector.name}</p>
                </div>
              )}
            </div>
            {!!selectedSector && (
              <div
                onClick={() => toggleProfessionModal('create')}
                className="px-4 py-2 bg-blue-500 rounded-md cursor-pointer"
              >
                <p className="text-white">Crear profesión</p>
              </div>
            )}
          </div>
          <div className="py-6 flex-grow">
            {!!loading && !!loadingProfessions && null}
            {!loading &&
              !loadingProfessions &&
              _.values(professions)
                .reverse()
                .map((profession) => {
                  return (
                    <div
                      onClick={() => selectProfession(profession)}
                      key={`profession__${profession._id}`}
                      className={`px-8 flex flex-col cursor-pointer ${
                        !!selectedProfession &&
                        selectedProfession._id === profession._id
                          ? 'bg-gray-200'
                          : ''
                      }`}
                    >
                      <div className="flex items-center  py-5">
                        <FiSettings className="mr-2 text-lg text-gray-700" />
                        <div
                          className={`w-1 h-1 mr-2 rounded-full bg-${
                            profession.removed === true ? 'red' : 'green'
                          }-400`}
                        />
                        <p className="text-lg text-gray-900">
                          {profession.name}
                        </p>
                      </div>
                      <div className="w-full h-1 border-b" />
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectorsAndProfessions;
