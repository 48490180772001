import {
  get as getAxios,
  patch as patchAxios,
  post as postAxios,
} from "../utils/requests-handlers";

const { REACT_APP_API_URL } = process.env;

export function getGlobalStats() {
  return getAxios(`${REACT_APP_API_URL}/v1/admin`);
}
export function getGlobalSkills(params) {
  return getAxios(`${REACT_APP_API_URL}/v1/admin/skills`, params);
}
export function getGlobalLevels(params) {
  return getAxios(`${REACT_APP_API_URL}/v1/commons/levels`, params);
}
export function getRequestedSkills() {
  return getAxios(`${REACT_APP_API_URL}/v1/admin/skills/requested`);
}
export function updateGlobalSkill(uid, payload) {
  return patchAxios(`${REACT_APP_API_URL}/v1/admin/skills/${uid}`, payload);
}
export function createGlobalSkill(payload) {
  return postAxios(`${REACT_APP_API_URL}/v1/admin/skills`, payload);
}
export function getGlobalSectors(payload) {
  return getAxios(`${REACT_APP_API_URL}/v1/admin/sector`, payload);
}
export function updateGlobalSector(uid, payload) {
  return patchAxios(`${REACT_APP_API_URL}/v1/admin/sector/${uid}`, payload);
}
export function createGlobalSector(payload) {
  return postAxios(`${REACT_APP_API_URL}/v1/admin/sector`, payload);
}
export function getGlobalProfessions(payload) {
  return getAxios(`${REACT_APP_API_URL}/v1/admin/profession`, payload);
}
export function updateGlobalProfession(uid, payload) {
  return patchAxios(`${REACT_APP_API_URL}/v1/admin/profession/${uid}`, payload);
}
export function createGlobalProfession(payload) {
  return postAxios(`${REACT_APP_API_URL}/v1/admin/profession`, payload);
}
export function notifyUsers(payload) {
  return postAxios(`${REACT_APP_API_URL}/v1/admin/notifications/`, payload);
}
