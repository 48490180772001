import reducer, { INITIAL_STATE } from './reducer';
import { ActionTypes } from './types';
import React, { Children, useReducer, useContext, createContext } from 'react';
import AppActions from './actions';
import { useMemoOne } from 'use-memo-one';

const Context = createContext({
  dispatch: () => {},
  state: INITIAL_STATE,
});

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ state, dispatch }}>
      {Children.only(children)}
    </Context.Provider>
  );
};

export const useAppState = () => {
  const { state, dispatch } = useContext(Context);
  const actions = useMemoOne(() => AppActions(dispatch), [
    state.user,
    state.actualPage,
  ]);

  return {
    state,
    actions,
    dispatch,
  };
};

export default Provider;

export { ActionTypes };
