import _ from "lodash";
import React from "react";
import shortUuid from "short-uuid";
import { AiOutlineUndo } from "react-icons/ai";
import { useReport } from "../contexts/reports/hooks";
import {
  VscAdd,
  VscCheck,
  VscCircleSlash,
  VscChevronRight,
} from "react-icons/vsc";

const PostsGrid = ({ reports, handleUpdateReportStatus }) => {
  const {
    actions: { selectReport },
  } = useReport();

  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-8">
      {_.values(reports).map((report) => {
        const { content } = report;
        return (
          report.content_type === "Post" && (
            <div
              key={`post__${shortUuid.generate()}`}
              className="border border-gray-200 py-3 rounded-lg bg-white shadow-lg flex flex-col w-full h-full"
            >
              <div className="px-3 inline-flex items-center">
                <img
                  className="w-6 h-6 rounded-full object-scale-down mr-2"
                  src={content.user.profile_photo}
                  alt={content.user.username}
                />
                <p className="text-sm truncate">
                  {content.user.first_name} {content.user.last_name}
                </p>
              </div>
              {content.medias.length > 0 &&
                (content.medias[0].type === "image" ? (
                  <img
                    src={content.medias[0].url}
                    className="w-full h-40 object-cover  mt-2"
                    alt=""
                  />
                ) : (
                  <video className="w-full h-40 object-cover mt-2" controls>
                    <source src={content.medias[0].url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ))}
              <p
                className={`px-3 text-sm break-all mt-2 flex-grow ${
                  content.medias.length > 0 ? "truncate" : "truncate-5-lines"
                }`}
              >
                {content.text}
              </p>
              <div
                onClick={() => selectReport(report)}
                className="px-3 w-full inline-flex items-center justify-between mt-2 text-blue-500"
              >
                <p className="cursor-pointer">{report.count} reportes</p>
                <VscChevronRight className="text-2xl cursor-pointer" />
              </div>
              <div className="px-3 w-full inline-flex items-center justify-center mt-2 ">
                {report.status === "pending" ? (
                  <>
                    <div
                      onClick={() => {
                        handleUpdateReportStatus(report._id, "ignored");
                      }}
                      className="inline-flex items-center mr-6 cursor-pointer"
                    >
                      <VscCheck className="text-gray-500 mr-1 text-xl" />
                      <p className="">Archivar</p>
                    </div>
                    <div
                      onClick={() => {
                        handleUpdateReportStatus(report._id, "blocked");
                      }}
                      className="inline-flex items-center cursor-pointer"
                    >
                      <VscCircleSlash className="text-gray-500 mr-1 text-xl" />
                      <p className="">Bloquear</p>
                    </div>
                  </>
                ) : (
                  <div
                    onClick={() => {
                      handleUpdateReportStatus(report._id, "pending");
                    }}
                    className="inline-flex items-center  cursor-pointer"
                  >
                    {report.status === "blocked" ? (
                      <VscAdd className="text-gray-500 mr-1 text-xl" />
                    ) : (
                      <AiOutlineUndo className="text-gray-500 mr-1 text-xl" />
                    )}
                    <p className="">
                      {report.status === "blocked"
                        ? "Desbloquear"
                        : "Desarchivar"}
                    </p>
                  </div>
                )}
              </div>
            </div>
          )
        );
      })}
    </div>
  );
};

export default PostsGrid;
