import _ from 'lodash'
import STATUS from "../constants/status";
import { VscClose } from "react-icons/vsc";
import { useSpring, animated } from "react-spring";
import { useCategoriesState } from "../contexts/categories";
import React, { useRef, useCallback, useEffect, useState } from "react";
import { useCategories } from "../contexts/categories/hooks";

const CustomModal = () => {
	const modalRef = useRef();
    const [reason, setReason] = useState('')
    const [ status, setStatus ] = useState("");
    const [previusStatus, setPreviusStatus] = useState("")
	const { state: { isModalOpen, selectedCategoryItem }, actions: { toggleCategoriesModal } } = useCategoriesState();
    const { actions: { updateItem, fetchCategoriesData, } } = useCategories({ isActive: false, status: previusStatus, limit: 8 , page: 1 });
	const item = selectedCategoryItem;
	
	
	const closeModal = useCallback(
		e => {
			if (modalRef.current === e.target) {
                toggleCategoriesModal();
                setStatus("");
                setPreviusStatus("");
			}
		},
		[ modalRef, setStatus, setPreviusStatus ]
	);

	const keyPress = useCallback(
		e => {
			if (e.key === "Escape" && isModalOpen) {
				toggleCategoriesModal();
			}
		},
		[ toggleCategoriesModal, isModalOpen ]
    );

    const handleResetState = useCallback(
        () => {
            setStatus("")
            setPreviusStatus("")
            setReason("")
        },
        [setStatus, setPreviusStatus],
    )

	const handlePress = useCallback(
		() => {
			const { item, store, category } = selectedCategoryItem;
            updateItem(category, store, { ...item, status, reason_rejection: reason  });
			toggleCategoriesModal();
			fetchCategoriesData();
            handleResetState();
		},
		[ selectedCategoryItem, status, toggleCategoriesModal, fetchCategoriesData, handleResetState, reason]
	);

	useEffect(
		() => {
			document.addEventListener("keydown", keyPress);
			return () => document.removeEventListener("keydown", keyPress);
		},
		[ keyPress ]
    );
    
    useEffect(() => {
        if(!!item){
            setReason(item.item.reason_rejection)
            setPreviusStatus(item.item.status)
            setStatus(item.item.status)
        }
    }, [item])

	const animation = useSpring({
		config: {
			duration: 250
		},
		opacity: isModalOpen ? 1 : 0,
		transform: isModalOpen ? `translateY(0%)` : `translateY(-100%)`,
		width: "35%",
		justifyContent: "center",
		alignItems: "center",
		overflow: "hidden",
		background: "white",
		borderRadius: "20px"
	});

	return (
		<React.Fragment>
			{!!isModalOpen && (
				<div
					onClick={closeModal}
					ref={modalRef}
					className="bg-black bg-opacity-50 z-10 flex items-center justify-center fixed left-0 bottom-0 w-full h-full"
				>
					<animated.div style={animation}>
						<div className="rounded-2xl pt-5 pb-6">
							<div className="flex items-end justify-end">
								<VscClose
									className=" text-gray-800 text-4xl cursor-pointer mr-4"
									onClick={() => {
                                        toggleCategoriesModal();
                                        handleResetState();
                                    }}
								/>
							</div>
							<div>
								<h1 className="text-2xl uppercase text-center">
									{item.category === "event" ? (
										"Evento"
									) : item.category === "job" ? (
										"Empleo"
									) : item.category === "program" ? (
										"Programa"
									) : item.category === "campaign" ? (
										"CrowdFunding"
									) : item.category === "course" ? (
										"Cursos"
									) : null}
								</h1>
								<div className="flex flex-col items-center tracking-wide mb-5 mt-8">
									<img
										className="w-20 h-20 rounded-full mb-4"
										src={
											item.category === "job" ? (
												item.item.publisher.profile_photo
											) : item.category === "campaign" ? (
												item.item.user.profile_photo
											) : (
												item.item.owner.profile_photo
											)
										}
										alt={
											item.category === "job" ? (
												item.item.publisher.first_name
											) : item.category === "campaign" ? (
												item.item.user.first_name
											) : (
												item.item.owner.first_name
											)
										}
									/>
									<p className="text-2xl text-gray-800">
										{item.category === "job" ? (
											`${item.item.publisher.first_name} ${item.item.publisher.last_name}`
										) : item.category === "campaign" ? (
											`${item.item.user.first_name} ${item.item.user.last_name}`
										) : (
											`${item.item.owner.first_name} ${item.item.owner.last_name}`
										)}
									</p>
								</div>
								<div className="border focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1 mx-10 mb-5">
									<div className="-mt-4 absolute tracking-wider px-1 uppercase text-xs">
										<p>
											<label htmlFor="reason" className="bg-white text-gray-600 px-1">
												Agregar motivo de rechazo
											</label>
										</p>
									</div>
									<textarea
										id="reason"
										autoComplete="false"
										tabIndex="0"
                                        type="text"
                                        value={reason}
                                        className="py-1 px-1 outline-none block h-full w-full"
                                        onChange={(event) => {
                                            setReason(event.target.value)
                                        }}
									/>
								</div>

								<div className="border focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1 mx-10 mb-10">
									<div className="relative">
										<select
											onChange={event => {
                                                setStatus(event.target.value);
											}}
											value={!!status ? status : item.item.status}
											className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded"
											id="grid-state"
										>
											{STATUS.map(item => {
												return (
														<option key={`status_${item.index}`} value={item.value}>
															{item.text}
														</option>
												);
											})}
										</select>
									</div>
								</div>
								<div className="flex flex-col items-center tracking-wide">
									<button
										onClick={handlePress}
										type="button"
										className="border border-gray-200 bg-gray-200 text-gray-700 rounded-md px-4 py-2 m-2 transition duration-500 ease select-none hover:bg-gray-300 focus:outline-none focus:shadow-outline "
									>
										Guardar cambios
									</button>
								</div>
							</div>
						</div>
					</animated.div>
				</div>
			)}
		</React.Fragment>
	);
};

export default CustomModal;
