import {
  Home,
  Users,
  Skills,
  Reports,
  Requests,
  Settings,
  Companies,
  Categories,
  Notifications,
  SectorsAndProfessions,
} from "../containers";

import {
  FaCog,
  FaStar,
  FaThLarge,
  FaChartPie,
  FaMailBulk,
  FaBullseye,
  FaUserCircle,
  FaCheckCircle,
  FaExclamationTriangle,
} from "react-icons/fa";

const pages = [
  {
    name: "Resumen",
    icon: FaChartPie,
    path: "/",
    component: Home,
  },
  {
    name: "Usuarios",
    icon: FaUserCircle,
    path: "/users",
    component: Users,
  },
  {
    name: "Paginas de Empresa",
    icon: FaUserCircle,
    path: "/companies",
    component: Companies,
  },
  {
    name: "Reportes",
    icon: FaExclamationTriangle,
    path: "/reports",
    component: Reports,
  },
  {
    name: "Solicitudes",
    icon: FaCheckCircle,
    path: "/requests",
    component: Requests,
  },
  {
    name: "Categorias",
    icon: FaThLarge,
    path: "/categories",
    component: Categories,
  },
  {
    name: "Categoría / Profesión",
    icon: FaBullseye,
    path: "/categories-professions",
    component: SectorsAndProfessions,
  },
  {
    name: "Habilidades",
    icon: FaStar,
    path: "/skills",
    component: Skills,
  },
  {
    name: "Notificaciones",
    icon: FaMailBulk,
    path: "/notifications",
    component: Notifications,
  },
  {
    name: "Configuraciones",
    icon: FaCog,
    path: "/settings",
    component: Settings,
  },
];

export default pages;
