import React from 'react';
import { TopBar } from './';

const Body = ({ children }) => {
  return (
    <div className="body-container flex flex-col items-start justify-start flex-1 overflow-y-auto p-8 bg-gray-200 box-border">
      <TopBar />
      <div className="box-border w-full max-w-full flex-grow">{children}</div>
    </div>
  );
};

export default Body;
