import { ActionTypes } from "./types";

export const INITIAL_STATE = {
  jobs: {},
  events: {},
  courses: {},
  programs: {},
  campaigns: {},
  jobsCount: 0,
  eventsCount: 0,
  coursesCount: 0,
  programsCount: 0,
  campaignsCount: 0,
  selectedItem: null,
  isModalOpen: false,
  selectedCategoryItem: undefined,
};

const reducerMap = {
  [ActionTypes.SET_JOBS]: (state, { jobs }) => {
    return {
      ...state,
      jobs: jobs.reduce((acc, job) => {
        if (job._id) {
          acc[job._id] = job;
        }
        return acc;
      }, {}),
    };
  },
  [ActionTypes.SET_EVENTS]: (state, { events }) => {
    return {
      ...state,
      events: events.reduce((acc, event) => {
        if (event._id) {
          acc[event._id] = event;
        }
        return acc;
      }, {}),
    };
  },
  [ActionTypes.SET_COURSES]: (state, { courses }) => {
    return {
      ...state,
      courses: courses.reduce((acc, course) => {
        if (course._id) {
          acc[course._id] = course;
        }
        return acc;
      }, {}),
    };
  },
  [ActionTypes.SET_PROGRAMS]: (state, { programs }) => {
    return {
      ...state,
      programs: programs.reduce((acc, program) => {
        if (program._id) {
          acc[program._id] = program;
        }
        return acc;
      }, {}),
    };
  },
  [ActionTypes.SET_CAMPAIGNS]: (state, { campaigns }) => {
    return {
      ...state,
      campaigns: campaigns.reduce((acc, campaign) => {
        if (campaign._id) {
          acc[campaign._id] = campaign;
        }
        return acc;
      }, {}),
    };
  },
  [ActionTypes.SET_CATEGORIES_DATA]: (
    state,
    {
      jobs,
      events,
      courses,
      programs,
      campaigns,
      jobsCount,
      eventsCount,
      coursesCount,
      programsCount,
      campaignsCount,
    }
  ) => {
    return {
      jobsCount,
      eventsCount,
      coursesCount,
      programsCount,
      campaignsCount,
      jobs: jobs.reduce((acc, job) => {
        if (job._id) {
          acc[job._id] = job;
        }
        return acc;
      }, {}),
      events: events.reduce((acc, event) => {
        if (event._id) {
          acc[event._id] = event;
        }
        return acc;
      }, {}),
      courses: courses.reduce((acc, course) => {
        if (course._id) {
          acc[course._id] = course;
        }
        return acc;
      }, {}),
      programs: programs.reduce((acc, program) => {
        if (program._id) {
          acc[program._id] = program;
        }
        return acc;
      }, {}),
      campaigns: campaigns.reduce((acc, campaign) => {
        if (campaign._id) {
          acc[campaign._id] = campaign;
        }
        return acc;
      }, {}),
    };
  },
  [ActionTypes.UPDATE_CATEGORY_ITEM]: (state, { store, item }) => {
    state[store][item._id] = item;
    return { ...state, };
  },
  [ActionTypes.SET_SELECTED_CATEGORY_ITEM]: (
    state,
    { selectedCategoryItem }
  ) => {
    return { ...state, selectedCategoryItem };
  },
  [ActionTypes.TOGGLE_USER_MODAL]: (state) => {
    return { ...state, isModalOpen: !state.isModalOpen };
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  return action.type in reducerMap
    ? reducerMap[action.type]({ ...state }, action)
    : state;
};

export default reducer;
