export const ActionTypes = {
  SET_LEVELS: "SET_LEVELS",
  SET_SKILLS: "SET_SKILLS",
  SET_SECTORS: "SET_SECTORS",
  UPDATE_SKILL: "UPDATE_SKILL",
  UPDATE_SECTOR: "UPDATE_SECTOR",
  SET_PROFESSIONS: "SET_PROFESSIONS",
  UPDATE_PROFESSION: "UPDATE_PROFESSION",
  SET_SELECTED_SKILL: "SET_SELECTED_SKILL",
  TOGGLE_SKILL_MODAL: "TOGGLE_SKILL_MODAL",
  SET_SELECTED_SECTOR: "SET_SELECTED_SECTOR",
  TOGGLE_SECTOR_MODAL: "TOGGLE_SECTOR_MODAL",
  SET_SELECTED_PROFESSION: "SET_SELECTED_PROFESSION",
  TOGGLE_PROFESSION_MODAL: "TOGGLE_PROFESSION_MODAL",
};
