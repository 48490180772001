const TABS = [
  { title: 'Empleo', type: 'job' },
  { title: 'Programa', type: 'program' },
  { title: 'Curso', type: 'course' },
  { title: 'Crowdfunding', type: 'crowdfunding' },
  { title: 'Eventos', type: 'event' },
  { title: 'Paginas de empresa', type: 'company' },
];

export default TABS;
