import React from "react";
import moment from "moment";
import Router from "./router";
import ReactDOM from "react-dom";
import localization from "moment/locale/es";

import AppStateProvider from "./contexts/app";
import AdminStateProvider from "./contexts/admin";
import UsersStateProvider from "./contexts/users";
import ReportsStateProvider from "./contexts/reports";
import CompaniesStateProvider from "./contexts/companies";
import CategoriesStateProvider from "./contexts/categories";

import "./assets/styles/main.css";
import "./assets/styles/tooltip.css";

moment.locale("es", localization);

ReactDOM.render(
  <UsersStateProvider>
    <ReportsStateProvider>
      <AppStateProvider>
        <AdminStateProvider>
          <CategoriesStateProvider>
            <CompaniesStateProvider>
              <Router />
            </CompaniesStateProvider>
          </CategoriesStateProvider>
        </AdminStateProvider>
      </AppStateProvider>
    </ReportsStateProvider>
  </UsersStateProvider>,
  document.getElementById("root")
);
