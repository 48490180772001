export const EXTRA_TOPICS = [
  { value: "hiring-talent", label: "Contratar talentos" },
  { value: "upload-education", label: "Subir una formación" },
  { value: "boost-my-publications", label: "Potenciar mis publicaciones" },
  { value: "finding-a-job", label: "Encontrar trabajo" },
  { value: "training", label: "Formarme" },
  { value: "make-contacts", label: "Hacer contactos" },
  { value: "growing-my-brand", label: "Hacer crecer mi marca" },
  {
    value: "offer-professional-services",
    label: "offer-professional-services",
  },
  {
    value: "boost-my-professional-profile",
    label: "Impulsar mi perfil profesional",
  },
  { value: "be-up-to-date-with-the-sector", label: "Estar al día del sector" },
  { value: "promote-event", label: "Promover eventos" },
  { value: "find-new-brands", label: "Encontrar nuevas marcas" },
  { value: "search-for-inspiration", label: "Buscar inspiración" },
];

export default EXTRA_TOPICS;
