import { useAdminState } from ".";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  getGlobalLevels,
  getGlobalSkills,
  getGlobalSectors,
  updateGlobalSkill,
  createGlobalSkill,
  updateGlobalSector,
  createGlobalSector,
  getRequestedSkills,
  getGlobalProfessions,
  createGlobalProfession,
  updateGlobalProfession,
} from "../../api/admin";

export const useGlobalSkills = () => {
  const {
    state: { skills, selectedSkill },
    actions: { setSkills },
  } = useAdminState();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [requestedSkills, setRequestedSkills] = useState([]);

  const fetchSkills = async () => {
    if (!loading) {
      setLoading(true);
    }
    try {
      const response = await Promise.all([
        getGlobalSkills({ limit: 0 }),
        getRequestedSkills(),
      ]);
      setSkills(response[0].skill);
      setRequestedSkills(response[1].skills);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.response.data);
    }
  };

  useEffect(() => {
    fetchSkills();
  }, []);

  return {
    skills,
    error,
    loading,
    selectedSkill,
    requestedSkills,
    actions: {},
  };
};

export const useSkill = () => {
  const {
    state: { selectedSkill, isSkillModalOpen, skillModalMode },
    actions: {
      setSelectedSkill,
      toggleSkillModal,
      updateSkill: updateSkillInState,
    },
  } = useAdminState();
  const [loading, setLoading] = useState(false);

  const selectSkill = (skill) => {
    setSelectedSkill(skill);
    toggleSkillModal("edit");
  };

  const updateSkill = async (uid, englishName, spanishName) => {
    setLoading(true);
    try {
      const response = await updateGlobalSkill(uid, {
        name: englishName.trim(),
        locales: { es: { name: spanishName.trim() } },
      });
      updateSkillInState(response.skill);
      setLoading(false);
      toggleSkillModal();
    } catch (error) {
      setLoading(false);
      console.log("Error updating skill", error.response.data);
    }
  };

  const createSkill = async (englishName, spanishName) => {
    setLoading(true);
    try {
      const response = await createGlobalSkill({
        uid: englishName.trim().toLowerCase(),
        name: englishName.trim(),
        locales: { es: { name: spanishName.trim() } },
      });
      updateSkillInState(response.skill);
      setLoading(false);
      toggleSkillModal();
    } catch (error) {
      setLoading(false);
      // console.log('Error creating skill', error.response.data);
    }
  };

  return {
    selectedSkill,
    isSkillModalOpen,
    skillModalMode,
    loading,
    actions: {
      selectSkill,
      toggleSkillModal,
      updateSkill,
      createSkill,
    },
  };
};

export const useGlobalSectors = ({ category }) => {
  const {
    state: { sectors, selectedSector },
    actions: { setSectors },
  } = useAdminState();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchSkills = async () => {
    if (!loading) {
      setLoading(true);
    }
    try {
      const response = await getGlobalSectors({ category });
      setSectors(response.sector);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.response.data || "");
    }
  };

  useEffect(() => {
    fetchSkills();
  }, [category]);

  return {
    sectors,
    error,
    loading,
    selectedSector,
    actions: {},
  };
};

export const useSector = () => {
  const {
    state: { selectedSector, isSectorModalOpen, sectorModalMode },
    actions: {
      setSelectedSector,
      toggleSectorModal,
      updateSector: updateSectorInState,
    },
  } = useAdminState();
  const [loading, setLoading] = useState(false);

  const selectSector = (sector) => {
    setSelectedSector(sector);
    if (!!selectedSector && selectedSector._id === sector._id) {
      toggleSectorModal("edit");
    }
  };

  const updateSector = async (uid, englishName, spanishName) => {
    setLoading(true);
    try {
      const response = await updateGlobalSector(uid, {
        name: englishName.trim(),
        locales: { es: { name: spanishName.trim() } },
      });
      updateSectorInState(response.sector);
      setLoading(false);
      toggleSectorModal();
    } catch (error) {
      setLoading(false);
      console.log("Error updating sector", error.response.data);
    }
  };

  const createSector = async (englishName, spanishName) => {
    setLoading(true);
    try {
      const response = await createGlobalSector({
        uid: englishName.trim().toLowerCase(),
        name: englishName.trim(),
        locales: { es: { name: spanishName.trim() } },
      });
      updateSectorInState(response.sector);
      setLoading(false);
      toggleSectorModal();
    } catch (error) {
      setLoading(false);
      console.log("Error creating sector", error.response.data);
    }
  };

  return {
    selectedSector,
    isSectorModalOpen,
    sectorModalMode,
    loading,
    actions: {
      selectSector,
      toggleSectorModal,
      updateSector,
      createSector,
    },
  };
};

export const useGlobalProfessions = ({ sectorUid }) => {
  const {
    state: { professions, selectedProfession },
    actions: { setProfessions },
  } = useAdminState();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchSkills = async () => {
    if (!loading) {
      setLoading(true);
    }
    try {
      const response = await getGlobalProfessions({ sectorUid });
      setProfessions(response.profession);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.response.data);
    }
  };

  useEffect(() => {
    if (sectorUid !== "") {
      fetchSkills();
    }
  }, [sectorUid]);

  return {
    professions,
    error,
    loading,
    selectedProfession,
    actions: {},
  };
};

export const useProfession = () => {
  const {
    state: {
      selectedProfession,
      isProfessionModalOpen,
      professionModalMode,
      selectedSector,
    },
    actions: {
      setSelectedProfession,
      toggleProfessionModal,
      updateProfession: updateProfessionInState,
    },
  } = useAdminState();
  const [loading, setLoading] = useState(false);

  const selectProfession = (profession) => {
    setSelectedProfession(profession);
    toggleProfessionModal("edit");
  };

  const updateProfession = async (uid, englishName, spanishName) => {
    setLoading(true);
    try {
      const response = await updateGlobalProfession(uid, {
        name: englishName.trim(),
        locales: { es: { name: spanishName.trim() } },
      });
      updateProfessionInState(response.profession);
      setLoading(false);
      toggleProfessionModal();
    } catch (error) {
      setLoading(false);
      console.log("Error updating profession", error.response.data);
    }
  };

  const createProfession = async (englishName, spanishName) => {
    setLoading(true);
    try {
      const response = await createGlobalProfession({
        uid: englishName.trim().toLowerCase(),
        name: englishName.trim(),
        locales: { es: { name: spanishName.trim() } },
        sectorUid: selectedSector.uid,
      });
      updateProfessionInState(response.profession);
      setLoading(false);
      toggleProfessionModal();
    } catch (error) {
      setLoading(false);
      console.log("Error creating profession", error.response.data);
    }
  };

  return {
    selectedProfession,
    isProfessionModalOpen,
    professionModalMode,
    loading,
    actions: {
      selectProfession,
      toggleProfessionModal,
      updateProfession,
      createProfession,
    },
  };
};

export const useLevels = () => {
  const [data, setData] = useState({
    levels: [],
    loading: false,
    error: undefined,
  });

  const setter = useCallback(
    (newData) => setData({ ...data, ...newData }),
    [data]
  );

  const fetchLevels = useCallback(() => {
    getGlobalLevels()
      .then(({ levels }) => {
        // setLevels(levels);
        setter({ levels });
      })
      .catch((error) => setter({ error }))
      .finally(setter({ loading: false }));
  }, [setter]);

  useEffect(fetchLevels, []);

  return useMemo(
    () => ({
      data,
      fetchLevels,
    }),
    [data, fetchLevels]
  );
};
