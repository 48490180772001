// const TABS = [
//   { title: "Eventos", type: "Event" },
//   { title: "Crowdfunding", type: "Crowdfunding" },
//   {
//     title: "Formación",
//     type: "Training",
//     subTabs: [
//       { title: "Programas", type: "Program" },
//       { title: "Cursos", type: "Course" },
//     ],
//   },
//   { title: "Empleo", type: "Job" },
// ];

const TABS = {
  Event: { title: "Eventos", category_type: "event", store: "events" },
  Crowdfunding: {
    title: "Crowdfunding",
    category_type: "campaign",
    store: "campaigns",
  },
  Training: {
    title: "Formación",
    category_type: "formation",
    subTabs: [
      {
        title: "Programas",
        type: "Program",
        category_type: "program",
        store: "programs",
      },
      {
        title: "Cursos",
        type: "Course",
        category_type: "course",
        store: "courses",
      },
    ],
  },
  Job: { title: "Empleo", category_type: "job", store: "jobs" },
};

export default TABS;
