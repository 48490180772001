import { useReportsState } from ".";
import { useCallback, useEffect, useState } from "react";
import {
  getReports,
  getReporters,
  updateReport as updateReportAPI,
} from "../../api/reports";

export const useReports = ({ page, limit, contentType, status }) => {
  const {
    state: { reports },
    actions: { setReports, updateReport },
  } = useReportsState();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [reportsCount, setReportsCount] = useState(0);

  const fetchReports = useCallback(async () => {
    if (!loading) {
      setLoading(true);
    }
    if (!!reports) {
      setReports([]);
    }
    try {
      const response = await getReports({ page, limit, contentType, status });
      setReports(response.result.reports);
      setLoading(false);
      setReportsCount(response.result.count);
    } catch (error) {
      setLoading(false);
      setError(error.response.data);
    }
  }, [page, limit, contentType, status]);

  const updateReportStatus = useCallback(
    async (reportId, status) => {
      try {
        const response = await updateReportAPI(reportId, { status });
        updateReport(response.report);
      } catch (error) {
        setError(error.response.data);
      }
    },
    [updateReportAPI, updateReport, setError]
  );

  useEffect(() => {
    fetchReports();
  }, [page, limit, contentType, status]);

  return {
    reports,
    reportsCount,
    error,
    loading,
    actions: {
      fetchReports,
      updateReportStatus,
    },
  };
};

export const useReport = () => {
  const {
    state: { isReportModalOpen, selectedReport, selectedReportReporters },
    actions: {
      toggleReportModal,
      setSelectedReportReporters,
      setSelectedReport,
    },
  } = useReportsState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const selectReport = async (report) => {
    if (!loading) {
      setLoading(true);
    }
    setSelectedReport(report);
    toggleReportModal();
    try {
      const response = await getReporters(report._id);
      setSelectedReportReporters(response.result.reporters);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.response.data);
    }
  };

  return {
    isReportModalOpen,
    loading,
    reporters: selectedReportReporters,
    selectedReport,
    actions: {
      selectReport,
      toggleReportModal,
    },
  };
};
