import {
  Body,
  Sidebar,
  SkillModal,
  SectorModal,
  CompanyModal,
  UserDetailModal,
  ProfessionModal,
  ReportsListModal,
  CategoriesModal,
  UserDisableModal
} from "../components";
import "../assets/styles/app.css";
import { pages } from "../constants";
import { Login } from "../containers";
import React, { useEffect } from "react";
import { useAppState } from "../contexts/app";
import { useAuth } from "../contexts/app/hooks";
import { BrowserRouter, Route } from "react-router-dom";

const Router = ({ user }) => {
  const appState = useAppState();
  const authState = useAuth();

  useEffect(() => {
    authState.actions.loadLocalStorage();
  }, []);

  return (
    <BrowserRouter>
      {!!appState.state.user ? (
        <Route
          render={({ location }) => {
            return (
              <div className="app flex">
                <SkillModal />
                <SectorModal />
                <CompanyModal />
                <ProfessionModal />
                <UserDetailModal />
                <ReportsListModal />
                <CategoriesModal />
                <UserDisableModal />
                <Sidebar pages={pages} location={location} />
                <Body>
                  {pages.map((page, index) => {
                    return (
                      <Route
                        exact
                        key={index}
                        path={page.path}
                        component={page.component}
                      />
                    );
                  })}
                </Body>
              </div>
            );
          }}
        />
      ) : (
          <Route exact path="/" component={Login} />
        )}
    </BrowserRouter>
  );
};

export default Router;
