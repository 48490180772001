import { pages } from '../../constants';
import { ActionTypes } from './types';

export const INITIAL_STATE = {
  actualPage: pages[0],
  user: null,
  selectedReport: null,
};

const reducerMap = {
  [ActionTypes.SET_ACTUAL_PAGE]: (state, { page }) => {
    return { ...state, actualPage: page };
  },
  [ActionTypes.SET_USER]: (state, { user }) => {
    return { ...state, user };
  },
  [ActionTypes.SET_SELECTED_REPORT]: (state, { selectedReport }) => {
    return { ...state, selectedReport };
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  return action.type in reducerMap
    ? reducerMap[action.type]({ ...state }, action)
    : state;
};

export default reducer;
