import React from 'react';
import Lottie from 'lottie-react-web';
import animation from '../../assets/lottie/27330-construction-in-process-by-kit8net.json';
const Settings = () => {
  return (
    <div className="h-full">
      <Lottie
        width="60%"
        height="90%"
        options={{
          animationData: animation,
        }}
      />
      <h1 className=" text-center font-bold text-red-600 text-3xl">
        La sección actual se encuentra en construcción
      </h1>
    </div>
  );
};

export default Settings;
