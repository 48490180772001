import {
  get as getAxios,
  post as postAxios,
  patch as patchAxios,
} from "../utils/requests-handlers";

const { REACT_APP_API_URL } = process.env;

export function signInWithEmailAndPassword(payload) {
  return postAxios(`${REACT_APP_API_URL}/v1/admin/accounts/signin`, {
    username: payload.email,
    password: payload.password,
  });
}

export function getUsers(payload = { page: 1, limit: 10, name: "" }) {
  return getAxios(`${REACT_APP_API_URL}/v1/admin/accounts`, payload);
}

export function getUser(username) {
  return getAxios(`${REACT_APP_API_URL}/v1/admin/accounts/${username}`);
}

export function updateUser(username, payload) {
  return patchAxios(
    `${REACT_APP_API_URL}/v1/admin/accounts/${username}`,
    payload // level: 0/1/2/3 || removed: true/false || verified: true/false
  );
}

export function getVerificationRequests(payload = { page: 1, limit: 10 }) {
  return getAxios(
    `${REACT_APP_API_URL}/v1/admin/requests/verification`,
    payload
  );
}

export function updateVerificationRequest(id, payload) {
  return patchAxios(
    `${REACT_APP_API_URL}/v1/admin/requests/verification/${id}`,
    payload // status: pending, approved, declined
  );
}

export function changePassword(payload) {
  return patchAxios(
    `${REACT_APP_API_URL}/v1/admin/accounts/changePassword/`,
    payload
  );
}
