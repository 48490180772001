import _ from "lodash";
import moment from "moment";
import { Children } from "react";
import { Paginator } from "../../components";
import { CustomTable } from "../Categories/Auxiliar";
import { useUsers } from "../../contexts/users/hooks";
import usePagination from "../../hooks/usePagination";
import { EXTRA_TOPICS, COUNTRIES } from "../../constants";
import { useGlobalSkills } from "../../contexts/admin/hooks";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { getNotificationsLog as getNotificationsLogApi } from "../../api/audit";

const INITIAL_LIMIT = 6;

const countries = _.orderBy(
  _.map(_.toPairs(COUNTRIES), (array) =>
    _.zipObject(["value", "label"], array)
  ),
  ["label", "asc"]
);

const USERS_TABLE_HEADERS = [
  { title: "Nombre", uid: "is_completed" },
  {
    title: "Email/Telefono",
    uid: "number",
    formatter: (item, row) => (
      <div className="break-words">
        {!_.isEmpty(item) ? item.data.number : row.email}
      </div>
    ),
  },
  { title: "Opciones", uid: "options" },
];

const NOTIFICATIONS_TABLE_HEADER = [
  { title: "Tipo de canal", uid: "topic_type" },
  { title: "Canal", uid: "topic" },
  { title: "Mensaje", uid: "message" },
  { title: "Usuarios notificados", uid: "count" },
  {
    title: "Creación",
    uid: "created_at",
    formatter: (item) => moment(item).format("DD-MM-YYYY - hh:mm a"),
  },
];

const Notifications = () => {
  const paginatorRef = useRef(null);

  const { page, limit, setLimit, setPage } = usePagination(1, INITIAL_LIMIT);

  const [name, setName] = useState("");
  const [skill, setSkill] = useState("");
  const [level, setLevel] = useState("");
  const [survey, setSurvey] = useState("");
  const [message, setMessage] = useState("");
  const [nationality, setnationality] = useState("");
  const [usersFiltered, setUsersFiltered] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [notificationsLog, setNotificationsLog] = useState([]);

  const { skills } = useGlobalSkills();
  const {
    users,
    loading,
    notifying,
    usersCount,
    actions: { notifyUsers },
  } = useUsers({ page, limit, name, skill, level, nationality, survey });

  const isSendButtonDisabled = !message.trim() && !selectedUsers.length;

  useEffect(() => {
    setUsersFiltered(
      _.differenceWith(_.values(users), selectedUsers, _.isEqual)
    );
  }, [users]);

  const getNotificationsLog = () => {
    getNotificationsLogApi()
      .then(({ result }) => setNotificationsLog(result ?? []))
      .catch((error) => console.log(`Error getting notifications log`, error));
  };

  useEffect(getNotificationsLog, []);

  const optionsComponentSelect = useCallback(
    (item) => {
      const enableSelect =
        !skill.trim() && !level.trim() && !nationality.trim() && !survey.trim();
      return (
        <div
          onClick={() => {
            enableSelect && handleSelectUser(item);
          }}
        >
          <div className={!skill.trim() ? "cursor-pointer p-1" : "p-1"}>
            {enableSelect ? "Seleccionar" : "Seleccionado"}
          </div>
        </div>
      );
    },
    [users, usersFiltered, selectedUsers]
  );

  const optionsComponentUnselect = useCallback(
    (item) => {
      return (
        <div
          className="cursor-pointer"
          onClick={() => handleUnselectUser(item)}
        >
          <button className="p-1">Remover</button>
        </div>
      );
    },
    [users, usersFiltered, selectedUsers]
  );

  const handleSelectUser = useCallback(
    (user) => {
      const selectedUsersCopy = selectedUsers;
      selectedUsersCopy.push(user);
      setSelectedUsers(selectedUsersCopy);
      const availableUsers = _.differenceWith(
        _.values(users),
        selectedUsers,
        _.isEqual
      );
      setUsersFiltered(availableUsers);
      if (!availableUsers.length) {
        !!name.trim()
          ? setName("")
          : paginatorRef.current.handleChangePage("forward");
      }
    },
    [usersFiltered, selectedUsers, name]
  );

  const handleUnselectUser = useCallback(
    (user) => {
      _.remove(selectedUsers, (item) => user === item);
      const selectedUsersCopy = selectedUsers;
      setSelectedUsers(selectedUsersCopy);
      setUsersFiltered(
        _.differenceWith(_.values(users), selectedUsers, _.isEqual)
      );
    },
    [usersFiltered, selectedUsers]
  );

  const handleNotifyUsers = useCallback(() => {
    if (!!message.trim()) {
      const payload =
        skill === "general"
          ? { message, topic: skill, topicType: "general" }
          : {
              message,
              topic: !!skill
                ? skill
                : !!level
                ? level
                : !!nationality
                ? nationality
                : survey,
              users: !!selectedUsers.length && _.map(selectedUsers, "_id"),
              topicType: !!skill ? "skill" : !!level ? "level" : "nationality",
            };
      notifyUsers(payload);
      if (!notifying) {
        setMessage("");
        setSelectedUsers([]);
        setPage(1);
        setName("");
        setSurvey("");
      }
    }
  }, [notifying, message, selectedUsers, page, name, notifyUsers]);

  return (
    <>
      <div className="w-full bg-white rounded-tr-lg rounded-b-lg shadow-md p-6 pt-8">
        <div key="message_input_area" className="my-3">
          <label
            htmlFor="message"
            className="text-xl block font-medium text-gray-700 mb-5"
          >
            Mensaje
          </label>
          <div className="flex flex-row mt-1">
            <textarea
              rows="3"
              id="message"
              name="message"
              value={message}
              placeholder="Ej: Feliz navidad!"
              onChange={({ target: { value } }) => setMessage(value)}
              className="p-3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
            />
            <button
              type="button"
              onClick={handleNotifyUsers}
              disabled={notifying || isSendButtonDisabled}
              className="m-3 bg-red-300 p-3 text-white rounded-lg disabled:opacity-10"
            >
              Enviar
            </button>
          </div>
          <p className="mt-2 text-sm text-gray-500">
            Escribe el mensaje que deseas enviar a los usuarios de Adictic
          </p>
        </div>

        <div className="my-10" key="search_user_area">
          <label className="text-xl block font-medium text-gray-700 mb-5">
            Filtros
          </label>

          <div className="flex">
            <input
              value={name}
              type="search"
              placeholder="Buscar por nombre..."
              className="mb-5 min-w-max bg-purple-white shadow rounded border-0 p-3 mr-2"
              onChange={({ target: { value } }) => {
                setPage(1);
                setName(value);
              }}
            />
          </div>

          <label className="text-xl block font-medium text-gray-700 mb-5">
            Canales
          </label>

          <div className="flex flex-row">
            <select
              value={skill}
              className="flex-1 mr-2 min-w-max bg-white rounded-md border-0 shadow focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={({ target: { value } }) => {
                setPage(1);
                setSkill(value);
                setnationality("");
                setLevel("");
                setSurvey("");
              }}
            >
              <option value="">Habilidad</option>
              <option value="general">General</option>
              {_.map(_.values(skills), (skill) => {
                return <option value={skill.uid}>{skill.name}</option>;
              })}
            </select>

            <select
              value={level}
              className="flex-1 mx-2 min-w-max bg-white rounded-md border-0 shadow focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={({ target: { value } }) => {
                setPage(1);
                setLevel(value);
                setSkill("");
                setnationality("");
                setSurvey("");
              }}
            >
              <option value="">Nivel</option>
              <option value="rookie">Rookie</option>
              <option value="professional">Professional</option>
              <option value="adicter">Adicter</option>
              <option value="premium">Premium</option>
            </select>

            <select
              value={nationality}
              className="flex-1 mx-2 min-w-max bg-white rounded-md border-0 shadow focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={({ target: { value } }) => {
                setPage(1);
                setnationality(value);
                setSkill("");
                setLevel("");
                setSurvey("");
              }}
            >
              <option value="">Nacionalidad</option>
              {Children.toArray(
                _.map(countries, ({ label, value }) => (
                  <option value={value}>{label}</option>
                ))
              )}
            </select>

            <select
              value={survey}
              className="flex-1 mx-2 min-w-max bg-white rounded-md border-0 shadow focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={({ target: { value } }) => {
                setPage(1);
                setSurvey(value);
                setSkill("");
                setLevel("");
                setnationality("");
              }}
            >
              <option value="">Survey</option>
              {Children.toArray(
                _.map(EXTRA_TOPICS, ({ value, label }) => (
                  <option value={value}>{label}</option>
                ))
              )}
            </select>
          </div>
        </div>

        <div key="users_picker" className="my-3">
          <div
            key="select_users_area"
            className="flex flex-row flex-grow h-100 mb-10"
          >
            <div key="available_users_container" className="h-full">
              <label className="text-xl block font-medium text-gray-700 mb-5">
                {!skill.trim() ||
                !level.trim() ||
                !nationality.trim() ||
                !survey.trim()
                  ? "Selecciona los usuarios para notificar"
                  : "Se notificaran los siguientes usuarios"}
              </label>

              <div className="flex w-full h-full">
                <CustomTable
                  loading={loading}
                  data={usersFiltered}
                  headers={USERS_TABLE_HEADERS}
                  handleSelectItem={handleSelectUser}
                  optionsComponent={optionsComponentSelect}
                />
              </div>
            </div>

            {!skill.trim() &&
              !level.trim() &&
              !nationality.trim() &&
              !survey.trim() && (
                <div key="selected_users_container">
                  <label className="text-xl block font-medium text-gray-700 mb-5">
                    Usuarios seleccionados
                  </label>
                  <div className="h-full overflow-auto">
                    <div className="">
                      <CustomTable
                        loading={false}
                        data={selectedUsers}
                        headers={USERS_TABLE_HEADERS}
                        handleSelectItem={handleUnselectUser}
                        optionsComponent={optionsComponentUnselect}
                        customPlaceholder="Aun no has seleccionado usuarios para notificar"
                      />
                    </div>
                  </div>
                </div>
              )}
          </div>

          <Paginator
            page={page}
            limit={limit}
            setPage={setPage}
            ref={paginatorRef}
            setLimit={setLimit}
            itemsCount={usersCount}
            initialLimit={INITIAL_LIMIT}
          />
        </div>
      </div>

      <div className="w-full bg-white rounded-tr-lg rounded-b-lg shadow-md mt-10 px-6 py-8">
        <label className="text-xl font-medium text-gray-700 mb-5">
          Historial de notificaciones
        </label>

        <CustomTable
          data={notificationsLog}
          containerClassName="mt-10"
          headers={NOTIFICATIONS_TABLE_HEADER}
        />
      </div>
    </>
  );
};

export default Notifications;
