import { ActionTypes } from './types';

export const INITIAL_STATE = {
  companies: {},
  selectedCompany: null,
  isCompaniesModalOpen: false,
};

const reducerMap = {
  [ActionTypes.SET_COMPANIES]: (state, { companies }) => {
    return {
      ...state,
      companies: companies.reduce((acc, company) => {
        if (company._id) {
          acc[company._id] = company;
        }
        return acc;
      }, {}),
    };
  },
  [ActionTypes.UPDATE_COMPANY]: (state, { company }) => {
    state.companies[company._id] = company;
    return { ...state };
  },
  [ActionTypes.SET_SELECTED_COMPANY]: (state, { company }) => {
    return { ...state, selectedCompany: company };
  },
  [ActionTypes.TOGGLE_COMPANY_MODAL]: (state) => {
    return { ...state, isCompaniesModalOpen: !state.isCompaniesModalOpen };
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  return action.type in reducerMap
    ? reducerMap[action.type]({ ...state }, action)
    : state;
};

export default reducer;
