import React, { useCallback, useState } from "react";

const Tooltip = ({ message, position, ...props }) => {
  const [show, setShow] = useState(false);
  const hideTooltip = useCallback(() => {
    setShow(false);
  }, [setShow]);

  const showTooltip = useCallback(() => {
    setShow(true);
  }, [setShow]);

  return (
    <span className="tooltip" onMouseLeave={hideTooltip}>
      {show && (
        <div className={`tooltip-bubble z-50 tooltip-${position}`}>
          <div className="tooltip-message">{message}</div>
        </div>
      )}
      <span
        className="tooltip-trigger truncate w-full"
        onMouseOver={showTooltip}
      >
        {props.children}
      </span>
    </span>
  );
};

export default Tooltip;
