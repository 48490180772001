import React from "react";
import { CompaniesTable } from "../../components";

const Users = () => {
  return (
    <div className="h-full w-full">
      <CompaniesTable />
    </div>
  );
};

export default Users;
