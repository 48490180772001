const TABS = [
  { title: 'Publicaciones', type: 'Post' },
  { title: 'Personas', type: 'User' },
  { title: 'Eventos', type: 'Event' },
  { title: 'Crowdfunding', type: 'CrowdfundingCampaign' },
  {
    title: 'Formación',
    type: 'Training',
    subTabs: [
      { title: 'Programas', type: 'Program' },
      { title: 'Cursos', type: 'Course' },
    ],
  },
  { title: 'Empleo', type: 'Job' },
  { title: 'Empresas', type: 'Company' },
];

export default TABS;
