import _ from "lodash";
import { useCategoriesState } from ".";
import { useEffect, useState, useCallback } from "react";
import {
  getFilteredJobs,
  getFilteredEvents,
  getFilteredCourses,
  getFilteredPrograms,
  getFilteredCampaigns,
  updateCategoryItemStatus,
} from "../../api/categories";

export const useCategories = ({ status, page, limit, isActive = true }) => {
  const {
    state,
    actions: { setCategoriesData, updateCategoryItem, setSelectedCategoryItem, toggleCategoriesModal },
  } = useCategoriesState();
  const [isLoading, setIsLoading] = useState(false);
  const fetchCategoriesData = useCallback(async () => {
    setIsLoading(true);
    try {
      const { ungroupedJobs, jobsCount } = await getFilteredJobs({
        page,
        limit,
        status,
      });
      const { events, eventsCount } = await getFilteredEvents({
        page,
        limit,
        status,
      });
      const { courses, coursesCount } = await getFilteredCourses({
        page,
        limit,
        status,
      });
      const { programs, programsCount } = await getFilteredPrograms({
        page,
        limit,
        status,
      });
      const { campaigns, campaignsCount } = await getFilteredCampaigns({
        status,
        page,
        limit,
      });
      setCategoriesData({
        jobsCount,
        eventsCount,
        coursesCount,
        programsCount,
        campaignsCount,
        events: !events.length ? [] : events,
        courses: !courses.length ? [] : courses,
        programs: !programs.length ? [] : programs,
        campaigns: !campaigns.length ? [] : campaigns,
        jobs: !ungroupedJobs.length ? [] : ungroupedJobs,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
      // setError(error.response.data);
    }
  }, [status, page, limit]);

  const updateItem = useCallback((category, store, item) => {
    try {
      updateCategoryItemStatus({
        type: category,
        itemId: item._id,
        status: item.status,
        reason: item.reason_rejection
      })
        .then((response) => {
          updateCategoryItem({ store, item });
        })
        .catch((error) =>
          console.log("Error actualizando item de categoria", error)
        );
    } catch (error) {
      console.log("Error actualizando item", error);
    }
  }, []);

  const setCategoryItem = useCallback((selectedCategoryItem) => {
    setSelectedCategoryItem({ selectedCategoryItem });
  });

  useEffect(() => {
    if(isActive){
      fetchCategoriesData();
    }
  }, [status, page, limit, isActive]);

  return {
    state,
    isLoading,
    actions: { updateItem, setCategoryItem, toggleCategoriesModal, fetchCategoriesData, },
  };
};

export const useWaitingCategoriesCount = () => {
  const [count, setCount] = useState(0);

  const getWaitingCategoriesCount = async () => {
    const { jobsCount } = await getFilteredJobs({
      page: 0,
      limit: 0,
      status: "waiting",
    });
    const { eventsCount } = await getFilteredEvents({
      page: 0,
      limit: 0,
      status: "waiting",
    });
    const { coursesCount } = await getFilteredCourses({
      page: 0,
      limit: 0,
      status: "waiting",
    });
    const { programsCount } = await getFilteredPrograms({
      page: 0,
      limit: 0,
      status: "waiting",
    });
    const { campaignsCount } = await getFilteredCampaigns({
      status: "waiting",
      page: 0,
      limit: 0,
    });

    setCount(
      jobsCount + eventsCount + coursesCount + programsCount + campaignsCount
    );
  };

  useEffect(() => {
    getWaitingCategoriesCount();
  }, []);

  return { count, actions: { getWaitingCategoriesCount } };
};
