import { useCompanyPage } from '../contexts/companies/hooks';
import { VscClose, VscCloudUpload } from 'react-icons/vsc';
import animation from '../assets/lottie/6722-loading.json';
import React, { useEffect, useState } from 'react';
import Lottie from 'lottie-react-web';
import shortUuid from 'short-uuid';
import {
  useGlobalProfessions,
  useGlobalSectors,
} from '../contexts/admin/hooks';
import _ from 'lodash';
import { ImageCrop } from '.';
import { readFile } from '../utils';

const CompanyModal = () => {
  const [setSelectedSector] = useState();
  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const {
    company,
    loading,
    isCompaniesModalOpen,
    uploadingImage,
    actions: { toggleCompanyModal, updateCompany, updateImage },
  } = useCompanyPage();

  const { sectors, loading: loadingSectors } = useGlobalSectors({
    category: 'company',
  });
  const {
    professions,
    loading: loadingProfessions,
  } = useGlobalProfessions({
    sectorUid: !!company ? company.sector.uid : '',
  });

  useEffect(() => {
    if (croppedImage !== null && !!isCompaniesModalOpen) {
      updateImage(company.uid, croppedImage);
      setImage(null);
      setCroppedImage(null);
    }
  }, [croppedImage]);

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);
      setImage(imageDataUrl);
    }
  };

  return (
    <>
      {!!isCompaniesModalOpen && (
        <>
          {!!image && <ImageCrop image={image} setImage={setCroppedImage} />}
          <div className="absolute w-screen h-screen bg-black bg-opacity-50 z-10 flex justify-center items-center">
            <div className="bg-white rounded-2xl pt-12 pb-6 relative px-20 flex flex-col items-center">
              {!uploadingImage && !loading && (
                <VscClose
                  className=" text-gray-800 absolute top-0 right-0 text-4xl cursor-pointer mr-4 mt-4"
                  onClick={toggleCompanyModal}
                />
              )}
              <div className="w-72 flex flex-col items-center tracking-wide mb-5">
                <div className="relative ">
                  <img
                    className="w-20 h-20 rounded-full"
                    src={company.photo}
                    alt={company.name}
                  />

                  <div className="absolute right-0 bottom-0 cursor-pointer">
                    <div className="file-upload-adictic bg-gray-600 w-6 h-6 flex items-center justify-center rounded-full shadow-lg cursor-pointer">
                      <VscCloudUpload
                        className=" text-white text-lg cursor-pointer"
                        onClick={toggleCompanyModal}
                      />
                      <input
                        type="file"
                        multiple={false}
                        onChange={onFileChange}
                        className="cursor-pointer"
                        name="photo"
                        accept="image/png, image/jpeg"
                      />
                    </div>
                  </div>
                </div>
                <p className="text-2xl text-gray-800">{company.name}</p>
                <p className="text-base text-gray-600 mb-2 tracking-wide">
                  {`Dueño: ${company.owner.first_name} ${company.owner.last_name}`}
                </p>
                {!loadingSectors && (
                  <div className="inline-flex items-center">
                    <p className="mr-2">Sector:</p>
                    <select
                      onChange={(event) => {
                        const value = event.target.value;
                        setSelectedSector(value);
                        if (company.sector.uid !== value) {
                          updateCompany(company.uid, {
                            sectorUid: value,
                          });
                        }
                      }}
                      value={company.sector.uid}
                      className="block cursor-pointer focus:outline-none  text-gray-600"
                    >
                      {_.values(sectors).map((sector) => {
                        return (
                          <option
                            key={`sector__${shortUuid.generate()}`}
                            value={sector.uid}
                          >
                            {sector.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                )}
                {!loadingProfessions && (
                  <div className="inline-flex items-center ">
                    <p className="mr-2">Profesión:</p>
                    <select
                      onChange={(event) => {
                        const value = event.target.value;
                        if (
                          (Object.keys(company.profession).length > 0 &&
                            company.profession.uid !== value) ||
                          Object.keys(company.profession).length === 0
                        ) {
                          updateCompany(company.uid, {
                            professionUid: value,
                          });
                        }
                      }}
                      value={
                        Object.keys(company.profession).length > 0
                          ? company.profession.uid
                          : ''
                      }
                      className="block cursor-pointer focus:outline-none  text-gray-600"
                    >
                      <option selected hidden value="">
                        Selecciona una opción
                      </option>

                      {_.values(professions).map((profession) => {
                        return (
                          <option
                            key={`profession__${shortUuid.generate()}`}
                            value={profession.uid}
                          >
                            {profession.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                )}
              </div>

              {(!!uploadingImage || !!loading) && (
                <Lottie
                  width="15%"
                  height="15%"
                  options={{
                    animationData: animation,
                  }}
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CompanyModal;
