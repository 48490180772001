import _ from "lodash";
import shortUuid from "short-uuid";
import React, { useState } from "react";
import { CustomTable } from "./Auxiliar";
import { useMemoOne } from "use-memo-one";
import { Paginator } from "../../components";
import Tooltip from "../../components/Tooltip";
import usePagination from "../../hooks/usePagination";
import { useCategories } from "../../contexts/categories/hooks";
import { CATEGORIES_STATES, CATEGORIES_TABS } from "../../constants";

const EVENTS_TABLE_HEADERS = [
  { title: "Nombre", uid: "owner" },
  { title: "Imagen", uid: "media" },
  {
    title: "Url",
    uid: "url",
    formatter: (value) => (
      <a href={value} target="_blank" className="inline-block w-full truncate">
        {value}
      </a>
    ),
  },
  { title: "Titulo", uid: "title" },
  {
    title: "Descripcion",
    uid: "description",
    formatter: (value) => (
      <Tooltip message={value}>{_.truncate(value, { length: 40 })}</Tooltip>
    ),
  },
  // { title: "Reportes", uid: "reports" },
  { title: "Opciones", uid: "options" },
];

const CAMPAIGNS_TABLE_HEADERS = [
  { title: "Nombre", uid: "user" },
  { title: "Imagen", uid: "medias" },
  {
    title: "Url",
    uid: "url",
    formatter: (value) => (
      <a href={value} target="_blank">
        {value}
      </a>
    ),
  },
  { title: "Titulo", uid: "title" },
  {
    title: "Descripcion",
    uid: "description",
    formatter: (value) => (
      <Tooltip message={value}>
        <p className="px-5 py-5 border-b border-gray-200 cursor-pointer">
          {value}
        </p>
      </Tooltip>
    ),
  },
  // { title: "Reportes", uid: "reports" },
  { title: "Opciones", uid: "options" },
];

const JOBS_TABLE_HEADERS = [
  { title: "Nombre", uid: "publisher" },
  { title: "Empresa", uid: "company" },
  { title: "Titulo", uid: "profession" },
  {
    title: "Descripcion",
    uid: "description",
    formatter: (value) => (
      <Tooltip message={value}>{_.truncate(value, { length: 40 })}</Tooltip>
    ),
  },
  // { title: "Reportes", uid: "reports" },
  { title: "Opciones", uid: "options" },
];

const TRAINING_PROGRAM_TABLE_HEADERS = [
  { title: "Nombre", uid: "owner" },
  { title: "Empresa", uid: "company" },
  { title: "Titulo", uid: "name" },
  {
    title: "Descripcion",
    uid: "description",
    formatter: (value) => (
      <Tooltip message={value}>{_.truncate(value, { length: 40 })}</Tooltip>
    ),
  },
  // { title: "Reportes", uid: "reports" },
  { title: "Opciones", uid: "options" },
];

const TRAINING_COURSE_TABLE_HEADERS = [
  { title: "Nombre", uid: "owner" },
  { title: "Empresa", uid: "company" },
  { title: "Titulo", uid: "title" },
  {
    title: "Descripcion",
    uid: "description",
    formatter: (value) => (
      <Tooltip message={value}>{_.truncate(value, { length: 40 })}</Tooltip>
    ),
  },
  // { title: "Reportes", uid: "reports" },
  { title: "Opciones", uid: "options" },
];

const Categories = () => {
  const [selectedTab, setSelectedTab] = useState(CATEGORIES_TABS.Event);
  const [selectedState, setSelectedState] = useState(CATEGORIES_STATES[0]);
  const [selectedSubTab, setSelectedSubTab] = useState(
    CATEGORIES_TABS.Training.subTabs[0].type
  );

  const { page, limit, setLimit, setPage } = usePagination(1, 8);
  const {
    isLoading,
    state: {
      jobsCount,
      eventsCount,
      coursesCount,
      programsCount,
      campaignsCount,
      jobs,
      events,
      courses,
      programs,
      campaigns,
    },
    actions: { updateItem, setCategoryItem, toggleCategoriesModal },
  } = useCategories({ status: selectedState.type, page, limit });

  console.log(`events`, events);
  const { tableHeaders, dataToDisplay, dataCount } = useMemoOne(() => {
    return CATEGORIES_TABS.Event == selectedTab
      ? {
          dataCount: eventsCount,
          dataToDisplay: _.values(events),
          tableHeaders: EVENTS_TABLE_HEADERS,
        }
      : CATEGORIES_TABS.Crowdfunding == selectedTab
      ? {
          dataCount: campaignsCount,
          dataToDisplay: _.values(campaigns),
          tableHeaders: CAMPAIGNS_TABLE_HEADERS,
        }
      : CATEGORIES_TABS.Job == selectedTab
      ? {
          dataCount: jobsCount,
          dataToDisplay: _.values(jobs),
          tableHeaders: JOBS_TABLE_HEADERS,
        }
      : CATEGORIES_TABS.Training == selectedTab
      ? selectedSubTab == CATEGORIES_TABS.Training.subTabs[0].type
        ? {
            dataCount: programsCount,
            dataToDisplay: _.values(programs),
            tableHeaders: TRAINING_PROGRAM_TABLE_HEADERS,
          }
        : {
            dataCount: coursesCount,
            dataToDisplay: _.values(courses),
            tableHeaders: TRAINING_COURSE_TABLE_HEADERS,
          }
      : [];
  });

  const { category, store } =
    selectedTab.category_type == CATEGORIES_TABS.Training.category_type
      ? selectedSubTab == CATEGORIES_TABS.Training.subTabs[0].type
        ? {
            category: CATEGORIES_TABS.Training.subTabs[0].category_type,
            store: CATEGORIES_TABS.Training.subTabs[0].store,
          }
        : {
            category: CATEGORIES_TABS.Training.subTabs[1].category_type,
            store: CATEGORIES_TABS.Training.subTabs[1].store,
          }
      : { category: selectedTab.category_type, store: selectedTab.store };

  const handleChangeTab = (tab) => {
    setPage(1);
    setSelectedTab(tab);
  };

  const handleChangeState = (stateItem) => {
    setSelectedState(stateItem);
  };

  const handleSelectItem = (item) => {
    setCategoryItem({ category, store, item });
  };

  const optionsComponent = (item) => {
    return (
      <button
        onClick={() => {
          handleSelectItem(item);
          toggleCategoriesModal();
        }}
        type="button"
        className="border border-gray-200 bg-gray-200 text-gray-700 rounded-md px-4 py-2 m-2 transition duration-500 ease select-none hover:bg-gray-300 focus:outline-none focus:shadow-outline"
      >
        Editar
      </button>
    );
  };

  return (
    <div className="h-full w-full box-border flex flex-col flex-grow">
      <div className="inline-flex w-full min-w-full">
        {_.values(CATEGORIES_TABS).map((tab) => {
          let count = 0;
          switch (tab) {
            case CATEGORIES_TABS.Event:
              count = eventsCount;
              break;
            case CATEGORIES_TABS.Crowdfunding:
              count = campaignsCount;
              break;
            case CATEGORIES_TABS.Training:
              count = programsCount + coursesCount;
              break;
            case CATEGORIES_TABS.Job:
              count = jobsCount;
              break;
          }
          return (
            <div
              style={{ minWidth: "150px" }}
              key={`tab__${shortUuid.generate()}`}
              onClick={() => handleChangeTab(tab)}
              className={`p-4 rounded-t-lg mr-6 cursor-pointer ${
                selectedTab === tab ? " bg-white shadow-md" : " bg-gray-400"
              }`}
            >
              <p className="tracking-wide text-center">
                {tab.title}{" "}
                {selectedState.type == "waiting" ? `- ${count}` : ""}
              </p>
            </div>
          );
        })}
      </div>

      <div className="h-full w-full bg-white flex flex-grow flex-col rounded-tr-lg rounded-b-lg shadow-md p-6 pt-8">
        {selectedTab == CATEGORIES_TABS.Training && (
          <div className="mb-4 flex" style={{ width: "fit-content" }}>
            {selectedTab.subTabs.map((subTabItem, index) => {
              return (
                <div
                  key={`subTab__${shortUuid.generate()}`}
                  className="flex flex-col items-center"
                >
                  <div
                    className={`inline-block px-4 py-2 w-40  cursor-pointer${
                      selectedSubTab === subTabItem.type ? " " : ""
                    }`}
                    onClick={() => setSelectedSubTab(subTabItem.type)}
                  >
                    <p
                      className={`tracking-wide text-center${
                        selectedSubTab === subTabItem.type ? " font-bold" : ""
                      }`}
                    >
                      {subTabItem.title}
                    </p>
                  </div>
                  <div
                    className={
                      selectedSubTab === subTabItem.type
                        ? "w-12 h-px rounded-lg bg-gray-900"
                        : "hidden"
                    }
                  />
                </div>
              );
            })}
          </div>
        )}

        <div
          style={{ width: "fit-content" }}
          className="bg-gray-300 rounded-full mb-4"
        >
          {CATEGORIES_STATES.map((stateItem) => {
            return (
              <div
                key={`state__${shortUuid.generate()}`}
                onClick={() => handleChangeState(stateItem)}
                className={`inline-block p-4 w-40 rounded-full cursor-pointer${
                  selectedState.type === stateItem.type
                    ? " bg-white shadow-xs border"
                    : ""
                }`}
              >
                <p className="tracking-wide text-center">{stateItem.title}</p>
              </div>
            );
          })}
        </div>

        <div className="w-full mb-4 flex-grow">
          <CustomTable
            loading={isLoading}
            data={dataToDisplay}
            headers={tableHeaders}
            handleSelectItem={handleSelectItem}
            optionsComponent={optionsComponent}
          />
        </div>
        <Paginator
          page={page}
          limit={limit}
          initialLimit={8}
          setPage={setPage}
          setLimit={setLimit}
          itemsCount={dataCount}
        />
      </div>
    </div>
  );
};

export default Categories;
