import _ from "lodash";
import { useUsersState } from ".";
import { useCallback, useEffect, useState } from "react";
import { notifyUsers as notifyUsersAPI } from "../../api/admin";
import {
   getUser,
   getUsers,
   getVerificationRequests,
   updateUser as updateUserAPI,
   changePassword as changePasswordAPI,
   updateVerificationRequest as updateVerificationRequestAPI,
} from "../../api/accounts";
import {
   getPageClaimRequests,
   getStoreRequests,
   updateStoreRequest as updateStoreRequestAPI,
   updatePageClaimRequest as updatePageClaimRequestAPI,
} from "../../api/company";

export const useUsers = ({
   page,
   name,
   limit,
   skill,
   level,
   survey,
   nationality,
}) => {
   const {
      state: { users },
      actions,
   } = useUsersState();
   const [error, setError] = useState(null);
   const [loading, setLoading] = useState(false);
   const [notifying, setNotifying] = useState(false);
   const [usersCount, setUsersCount] = useState(0);

   const fetchUsers = useCallback(async () => {
      if (!loading) {
         setLoading(true);
      }
      try {
         if (skill == "general") skill = "";
         const filters = _.omitBy(
            {
               page,
               name,
               limit,
               skill,
               level,
               survey,
               nationality,
            },
            (item) => !item
         );
         const response = await getUsers(filters);
         actions.setUsers(response.result.users);
         setUsersCount(response.result.count);
      } catch (error) {
         setError(error.response.data);
      } finally {
         setLoading(false);
      }
   }, [users, page, limit, name, skill, level, nationality, survey]);

   const notifyUsers = (payload) => {
      setNotifying(true);
      notifyUsersAPI(payload)
         .then(() => {
            setNotifying(false);
         })
         .catch((error) => {
            setNotifying(false);
            console.log("Error notificando usuarios", error);
            setError(error.response.data);
            throw error;
         });
   };

   useEffect(() => {
      fetchUsers();
   }, [page, limit, name, skill, level, nationality, survey]);

   return {
      users,
      usersCount,
      error,
      loading,
      notifying,
      actions: {
         fetchUsers,
         notifyUsers,
      },
   };
};

export const useUser = () => {
   const {
      state: { isUserModalOpen, selectedUser },
      actions,
   } = useUsersState();
   const [loading, setLoading] = useState(false);
   const [error, setError] = useState(null);
   const [isPasswordChanged, setIsPasswordChanged] = useState();

   const selectUser = async (username) => {
      if (!loading) {
         setLoading(true);
      }
      try {
         const response = await getUser(username);
         actions.setUser(response.user);
         setIsPasswordChanged();
         actions.toggleUserModal();
         setLoading(false);
      } catch (error) {
         setLoading(false);
         setError(error.response.data);
      }
   };

   const updateUser = async (username, payload) => {
      try {
         const response = await updateUserAPI(username, payload);
         actions.updateUser(response.user);
         if (!!selectedUser && selectedUser.username === username) {
            actions.setUser(response.user);
         }
         return response.user;
      } catch (error) {
         console.log(error);
         // setError(error.response.data);
      }
   };

   const changePassword = async (payload) => {
      try {
         if (payload.newPassword.trim()) {
            const response = await changePasswordAPI(payload);
            actions.updateUser(response.user);
            setIsPasswordChanged(true);
            if (!!selectedUser && selectedUser.username === payload.username) {
               actions.setUser(response.user);
            }
            return response.user;
         }
      } catch (error) {
         setIsPasswordChanged(false);
         console.log("Error cambiando contraseña", error);
      }
   };

   return {
      isUserModalOpen,
      isPasswordChanged,
      loading,
      user: selectedUser,
      actions: {
         selectUser,
         updateUser,
         selectedUser,
         changePassword,
         toggleUserModal: actions.toggleUserModal,
      },
   };
};

export const useUsersRequests = (
   { page, limit, type } = { page: 1, limit: 10, type: "StoreRequests" }
) => {
   const {
      state: { storeRequests, verificationRequests, pageClaimRequests },
      actions: {
         setStoreRequests,
         setPageClaimRequests,
         setVerificationRequests,
         updateStoreRequest: updateStoreRequestInState,
         updateVerificationRequest: updateVerificationRequestInState,
         updatePageClaimRequest: updatePageClaimRequestInState,
      },
   } = useUsersState();
   const {
      actions: { updateUser: updateUserInUserState },
   } = useUser();
   const [loading, setLoading] = useState(false);
   const [error, setError] = useState(null);

   const [requestsCount, setRequestsCount] = useState(0);

   const fetchStoreRequests = useCallback(async () => {
      if (!loading) {
         setLoading(true);
      }
      try {
         const response = await getStoreRequests({ page, limit });
         setStoreRequests(response.result.requests);
         setRequestsCount(response.result.count);
         setLoading(false);
      } catch (error) {
         setLoading(false);
         setError(error.response.data);
      }
   }, [page, limit]);

   const fetchVerificationRequests = useCallback(async () => {
      if (!loading) {
         setLoading(true);
      }
      try {
         const response = await getVerificationRequests({ page, limit });
         setVerificationRequests(response.result.requests);
         setRequestsCount(response.result.count);
         setLoading(false);
      } catch (error) {
         setLoading(false);
         setError(error.response.data);
      }
   }, [page, limit]);

   const fetchPageClaimRequests = useCallback(async () => {
      if (!loading) {
         setLoading(true);
      }
      try {
         const response = await getPageClaimRequests({ page, limit });
         setPageClaimRequests(response.result.requests);
         setRequestsCount(response.result.count);
         setLoading(false);
      } catch (error) {
         setLoading(false);
         setError(error.response.data);
      }
   }, [page, limit]);

   const updateStoreRequest = async (id, payload) => {
      try {
         const response = await updateStoreRequestAPI(id, payload);
         updateStoreRequestInState(response.request);
      } catch (error) {
         setError(error.response.data);
      }
   };
   const updateVerificationRequest = async (id, payload) => {
      try {
         const response = await updateVerificationRequestAPI(id, payload);
         updateVerificationRequestInState(response.request);
      } catch (error) {
         setError(error.response.data);
      }
   };
   const updatePageClaimRequest = async (id, payload) => {
      try {
         const response = await updatePageClaimRequestAPI(id, payload);
         updatePageClaimRequestInState(response.request);
      } catch (error) {
         setError(error.response.data);
      }
   };

   const updateUser = async (requestType, requestId, username, payload) => {
      const updatedUser = await updateUserInUserState(username, payload);

      if (requestType === "StoreRequests") {
         updateStoreRequestInState({ _id: requestId, user: updatedUser });
      } else if (requestType === "PageClaimRequest") {
         updatePageClaimRequestInState({ _id: requestId, user: updatedUser });
      } else {
         updateVerificationRequestInState({
            _id: requestId,
            user: updatedUser,
         });
      }
   };

   useEffect(() => {
      if (type === "StoreRequests") {
         fetchStoreRequests();
      } else if (type === "PageClaimRequests") {
         fetchPageClaimRequests();
      } else {
         fetchVerificationRequests();
      }
   }, [page, limit, type]);

   return {
      requestsCount,
      storeRequests,
      verificationRequests,
      pageClaimRequests,
      loading,
      actions: {
         updateStoreRequest,
         updateUser,
         updateVerificationRequest,
         updatePageClaimRequest,
      },
   };
};
