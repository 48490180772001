import _ from "lodash";
import React from "react";
import moment from "moment";
import Lottie from "lottie-react-web";
import { VscClose } from "react-icons/vsc";
import { useReport } from "../contexts/reports/hooks";
import animation from "../assets/lottie/27330-construction-in-process-by-kit8net.json";

const ReportsListModal = () => {
  const {
    loading,
    reporters,
    selectedReport,
    isReportModalOpen,
    actions: { toggleReportModal },
  } = useReport();
  return (
    <>
      {isReportModalOpen ? (
        <div className="absolute w-screen h-screen bg-black bg-opacity-50 z-10 flex justify-center items-center">
          <div
            className="bg-white rounded-2xl pt-12 pb-6 relative px-8 flex flex-col items-center"
            style={{ width: "500px" }}
          >
            <VscClose
              className=" text-gray-800 absolute top-0 right-0 text-4xl cursor-pointer mr-8 mt-4"
              onClick={toggleReportModal}
            />
            {!!loading ? (
              <Lottie
                width="20%"
                height="20%"
                options={{
                  animationData: animation,
                }}
              />
            ) : (
              <>
                <h1 className="text-2xl text-gray-700 font-bold tracking-wide mb-8">
                  {selectedReport.count}{" "}
                  {`reporte${selectedReport.count > 1 ? "s" : ""}`}
                </h1>
                {reporters.map((reporter) => (
                  <div
                    key={`reporter__${reporter._id}`}
                    className="flex justify-between w-full mb-4 text-lg text-gray-700"
                  >
                    <div className="flex items-center">
                      <img
                        src={reporter.user.profile_photo}
                        alt={reporter.user.username}
                        className={`w-8 h-8 rounded-full mr-3`}
                      />
                      <p className="font-semibold tracking-wide">{`${reporter.user.first_name} ${reporter.user.last_name}`}</p>
                    </div>
                    <p>{_.upperFirst(moment(reporter.created_at).fromNow())}</p>
                  </div>
                ))}
                {selectedReport.count > 20 && (
                  <p className="w-full text-lg text-gray-700 font-semibold tracking-wide">
                    y {selectedReport.count - 20} más
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ReportsListModal;
