const STATUS = [
    {
        index: 1,
        value: 'approved',
        text: 'Aprobar'
    },
    {
        index: 2,
        value: 'rejected',
        text: 'Denegar'
    },
    {
        index: 3,
        value: 'archived',
        text: 'Archivar'
    },
];

export default STATUS;
