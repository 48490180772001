import _ from "lodash";
import moment from "moment";
import shortUuid from "short-uuid";
import Paginator from "./Paginator";
import { FiEdit2 } from "react-icons/fi";
import { useAppState } from "../contexts/app";
import { getUsersReport } from "../api/audit";
import { useAdminState } from "../contexts/admin";
import { useUsersState } from "../contexts/users";
import usePagination from "../hooks/usePagination";
import React, { useCallback, useState } from "react";
import { VscCircleSlash, VscCheck } from "react-icons/vsc";
import { useUser, useUsers } from "../contexts/users/hooks";

const UsersTable = () => {
  const { actions } = useUsersState();
  const { page, limit, setLimit, setPage } = usePagination();
  const [name, setName] = useState("");
  const usersState = useUsers({ page, limit, name, skill: "" });
  const [loadingReport, setLoadingReport] = useState(false);
  const appState = useAppState();
  const {
    state: { levels },
  } = useAdminState();
  const {
    actions: { selectUser, updateUser },
  } = useUser();

  const handleDownloadReport = useCallback(
    (e) => {
      e.preventDefault();
      if (loadingReport) return;
      setLoadingReport(true);
      getUsersReport()
        .then(({ result }) => {
          window.open(result, "_blank");
        })
        .catch((error) => console.log(`Error descargando archivo`, error))
        .finally(() => setLoadingReport(false));
    },
    [setLoadingReport, getUsersReport, loadingReport]
  );

  return (
    <div className="p-8 min-w-full bg-white shadow-md rounded-lg h-full">
      <div className="my-2 flex justify-between ">
        <h3 className="text-xl font-bold">Usuarios</h3>
        <div className="flex items-center">
          <input
            type="search"
            placeholder="Buscar por nombre..."
            className="bg-purple-white shadow rounded border-0 p-3"
            onChange={(text) => {
              setName(text.target.value);
            }}
          />
        </div>
      </div>
      <div className="py-6 overflow-x-auto flex-grow">
        <div className="inline-block w-full overflow-hidden">
          <table className="w-full min-w-full leading-normal table-fixed">
            <thead>
              <tr>
                <th className="w-1/5 px-5 py-3 text-left text-xs font-semibold text-gray-600 tracking-wider">
                  Nombre
                </th>
                <th className="w-1/5 px-5 py-3 text-left text-xs font-semibold text-gray-600 tracking-wider">
                  Email/Teléfono
                </th>
                <th className="w-1/6 px-5 py-3 text-left text-xs font-semibold text-gray-600 tracking-wider">
                  Empresa
                </th>
                <th className="w-1/6 px-5 py-3 text-left text-xs font-semibold text-gray-600 tracking-wider">
                  Rol
                </th>
                <th className="w-2/6 px-5 py-3 text-left text-xs font-semibold text-gray-600 tracking-wider">
                  Motivo de cancelacíon
                </th>
                <th className="w-1/6 px-5 py-3 text-left text-xs font-semibold text-gray-600 tracking-wider">
                  Nivel
                </th>
                <th className="w-1/5 px-5 py-3 text-left text-xs font-semibold text-gray-600 tracking-wider">
                  Actividad reciente
                </th>
                <th className="w-1/12 px-2 py-3 text-left text-xs font-semibold text-gray-600 tracking-wider">
                  Bloquear
                </th>
              </tr>
            </thead>
            <tbody>
              {!usersState.loading &&
                _.values(usersState.users).map((user) => {
                  return (
                    <tr
                      key={`user__${shortUuid.generate()}`}
                      className={`${
                        !!user.removed ? "text-gray-500" : "text-gray-900"
                      }`}
                    >
                      <td className="px-5 py-5 border-b border-gray-200">
                        <div
                          onClick={() => selectUser(user.username)}
                          className="flex items-center cursor-pointer"
                        >
                          <div className="flex-shrink-0 w-10 h-10">
                            <img
                              className={`w-full h-full rounded-full ${
                                !!user.removed && "opacity-50"
                              }`}
                              src={user.profile_photo}
                              alt={user.first_name}
                            />
                          </div>
                          <div className="ml-3">
                            <p className="break-all">
                              {user.is_completed
                                ? `${user.first_name} ${user.last_name}`
                                : "Usuario nuevo"}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200">
                        <p className="break-all">
                          {user.number ? user.number.data.number : user.email}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200">
                        <p className="break-all">{user.current_company}</p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200">
                        <p className="break-all">{user.current_role}</p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200">
                        <p className="break-all">{user.reason_disabled}</p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 w-8 h-8">
                            <img
                              className={`w-full h-full rounded-full ${
                                !!user.removed && "opacity-50"
                              }`}
                              src={
                                !!user.verified
                                  ? "https://res.cloudinary.com/adictic/image/upload/v1606514251/levels/Ambassador_3x_uvcz70.png"
                                  : user.level.url
                              }
                              alt={user.level.name}
                            />
                          </div>
                          <div className="ml-3">
                            <FiEdit2
                              onClick={() => selectUser(user.username)}
                              className="cursor-pointer text-gray-600"
                            />
                          </div>
                        </div>
                      </td>

                      <td className="px-5 py-5 border-b border-gray-200">
                        <p className="break-all whitespace-no-wrap">
                          {!!user.last_activity
                            ? _.upperFirst(moment(user.last_activity).fromNow())
                            : "Ninguna"}
                        </p>
                      </td>
                      {appState.state.user.username !== user.username && (
                        <td className="px-5 py-5 border-b border-gray-200">
                          {!user.removed ? (
                            <VscCircleSlash
                              onClick={() => {
                                // updateUser(user.username, { removed: true })
                                actions.setUser(user);
                                actions.showDisableModal();
                              }}
                              className="cursor-pointer text-3xl text-gray-600"
                            />
                          ) : (
                            <VscCheck
                              onClick={() => {
                                updateUser(user.username, {
                                  removed: false,
                                  is_banned: false,
                                  reason: user.reason_disabled,
                                });
                              }}
                              className="cursor-pointer text-3xl text-gray-600"
                            />
                          )}
                        </td>
                      )}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>

      <section className="flex justify-between">
        <button
          name="download_report"
          title="Download report"
          disabled={loadingReport}
          onClick={handleDownloadReport}
          className="border border rounded p-3 focus:outline-none"
        >
          {loadingReport ? "Generando reporte..." : "Descargar reporte"}
        </button>

        <Paginator
          page={page}
          limit={limit}
          initialLimit={8}
          setPage={setPage}
          setLimit={setLimit}
          itemsCount={usersState.usersCount}
        />
      </section>
    </div>
  );
};

export default UsersTable;
