import React from "react";
import { useDashboardStats } from "../../contexts/app/hooks";

const Home = () => {
  const { stats } = useDashboardStats();
  return (
    <div className="">
      <div className="grid 2xl:grid-cols-4 gap-8 sm:grid-cols-3 ">
        <div className="bg-white w-64 rounded-md border border-gray-400 py-8 hover:border-blue-600 cursor-pointer w-full">
          <p className="text-gray-500 font-semibold tracking-wide text-xl text-center mb-2">
            Total de Reportes
          </p>
          <p className="text-center text-4xl font-bold">{stats.reportsCount}</p>
        </div>

        <div className="bg-white w-64 rounded-md border border-gray-400 py-8 hover:border-blue-600 cursor-pointer w-full">
          <p className="text-gray-500 font-semibold tracking-wide text-xl text-center mb-2">
            Reportes resueltos
          </p>
          <p className="text-center text-4xl font-bold">
            {stats.reportsSolvedCount}
          </p>
        </div>

        <div className="bg-white w-64 rounded-md border border-gray-400 py-8 hover:border-blue-600 cursor-pointer w-full">
          <p className="text-gray-500 font-semibold tracking-wide text-xl text-center mb-2">
            Total de Usuarios
          </p>
          <p className="text-center text-4xl font-bold">{stats.usersCount}</p>
        </div>
        <div className="bg-white w-64 rounded-md border border-gray-400 py-8 hover:border-blue-600 cursor-pointer w-full">
          <p className="text-gray-500 font-semibold tracking-wide text-xl text-center mb-2">
            Total de Empresas
          </p>
          <p className="text-center text-4xl font-bold">
            {stats.companiesCount}
          </p>
        </div>
        <div className="bg-white w-64 rounded-md border border-gray-400 py-8 hover:border-blue-600 cursor-pointer w-full">
          <p className="text-gray-500 font-semibold tracking-wide text-xl text-center mb-2">
            Total de Posts
          </p>
          <p className="text-center text-4xl font-bold">{stats.postsCount}</p>
        </div>
        <div className="bg-white w-64 rounded-md border border-gray-400 py-8 hover:border-blue-600 cursor-pointer w-full">
          <p className="text-gray-500 font-semibold tracking-wide text-xl text-center mb-2">
            Total de Empleos
          </p>
          <p className="text-center text-4xl font-bold">{stats.jobsCount}</p>
        </div>
        <div className="bg-white w-64 rounded-md border border-gray-400 py-8 hover:border-blue-600 cursor-pointer w-full">
          <p className="text-gray-500 font-semibold tracking-wide text-xl text-center mb-2">
            Total de Cursos
          </p>
          <p className="text-center text-4xl font-bold">{stats.coursesCount}</p>
        </div>
        <div className="bg-white w-64 rounded-md border border-gray-400 py-8 hover:border-blue-600 cursor-pointer w-full">
          <p className="text-gray-500 font-semibold tracking-wide text-xl text-center mb-2">
            Total de Programas
          </p>
          <p className="text-center text-4xl font-bold">
            {stats.programsCount}
          </p>
        </div>
        <div className="bg-white w-64 rounded-md border border-gray-400 py-8 hover:border-blue-600 cursor-pointer w-full">
          <p className="text-gray-500 font-semibold tracking-wide text-xl text-center mb-2">
            Total de Eventos
          </p>
          <p className="text-center text-4xl font-bold">{stats.eventsCount}</p>
        </div>
        <div className="bg-white w-64 rounded-md border border-gray-400 py-8 hover:border-blue-600 cursor-pointer w-full">
          <p className="text-gray-500 font-semibold tracking-wide text-xl text-center mb-2">
            Total de Campañas Crowdfunding
          </p>
          <p className="text-center text-4xl font-bold">
            {stats.crowdfundingCampaignsCount}
          </p>
        </div>
        <div className="bg-white w-64 rounded-md border border-gray-400 py-8 hover:border-blue-600 cursor-pointer w-full">
          <p className="text-gray-500 font-semibold tracking-wide text-xl text-center mb-2">
            Total de Empresas con Wallet
          </p>
          <p className="text-center text-4xl font-bold">
            {stats.companiesWithWallet}
          </p>
        </div>
        <div className="bg-white w-64 rounded-md border border-gray-400 py-8 hover:border-blue-600 cursor-pointer w-full">
          <p className="text-gray-500 font-semibold tracking-wide text-xl text-center mb-2">
            Total de Usuarios con Wallet
          </p>
          <p className="text-center text-4xl font-bold">
            {stats.usersWithWallet}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
