import _ from 'lodash';
import sha1 from 'sha1';

const {
  REACT_APP_CLOUDINARY_API_KEY,
  REACT_APP_CLOUDINARY_API_SECRET,
} = process.env;

// const CLOUDINARY_RAW_FILE_URL =
//   'https://api.cloudinary.com/v1_1/adictic/raw/upload';
// const CLOUDINARY_VIDEO_URL =
//   'https://api.cloudinary.com/v1_1/adictic/video/upload';
const CLOUDINARY_IMAGE_URL =
  'https://api.cloudinary.com/v1_1/adictic/image/upload';

const OMITTED_FIELDS = ['file', 'cloud_name', 'resource_type', 'api_key'];

const createSignature = async (payload, timestamp) => {
  const _payload = {
    ...payload,
    timestamp,
  };
  const stringPayload = _.map(
    _.keys(_.omit(_payload, OMITTED_FIELDS)),
    (key) => `${key}=${_payload[key]}`
  ).sort();
  return await sha1(
    _.join(stringPayload, '&') + REACT_APP_CLOUDINARY_API_SECRET
  );
};

export const uploadImage = async (file, folder) => {
  try {
    const timestamp = Math.round(new Date().getTime() / 1000);
    const formData = new FormData();
    const signature = await createSignature({ file, folder }, timestamp);

    formData.append('file', file);
    formData.append('api_key', REACT_APP_CLOUDINARY_API_KEY);
    formData.append('timestamp', timestamp);
    formData.append('signature', signature);
    formData.append('folder', folder);

    const res = await fetch(CLOUDINARY_IMAGE_URL, {
      method: 'POST',
      body: formData,
    });

    return await res.json();
  } catch (error) {
    console.log(error);
  }
};
