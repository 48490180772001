import { useEffect, useState } from 'react';
import { useAppState } from '.';
import { setAccessTokenForAxios } from '../../utils/requests-handlers';
import { signInWithEmailAndPassword } from '../../api/accounts';
import { getGlobalStats } from '../../api/admin';

export const useAuth = () => {
  const {
    state: { user },
    actions,
  } = useAppState();
  const [loginError, setLoginError] = useState(null);

  const loadLocalStorage = () => {
    const USER = localStorage.getItem('USER');
    if (USER) {
      const userObject = JSON.parse(USER);
      actions.setUser(userObject);
      setAccessTokenForAxios(userObject.access_token);
    }
  };

  const login = async (email, password) => {
    try {
      const response = await signInWithEmailAndPassword({ email, password });
      localStorage.setItem('USER', JSON.stringify(response.account));
      actions.setUser(response.account);
      setAccessTokenForAxios(response.account.access_token);
    } catch (error) {
      setLoginError(error);
    }
  };

  const logout = () => {
    localStorage.clear();
    actions.setUser(null);
  };

  return {
    user,
    loginError,
    actions: {
      login,
      logout,
      loadLocalStorage,
    },
  };
};

export const useDashboardStats = () => {
  const [stats, setStats] = useState({});

  const fetchData = async () => {
    try {
      const response = await getGlobalStats();
      setStats(response.result);
    } catch (error) {
      console.log(error.response.data);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { stats };
};
