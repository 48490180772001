import { ActionTypes } from "./types";

export const INITIAL_STATE = {
  users: {},
  storeRequests: {},
  selectedUser: null,
  pageClaimRequests: {},
  isUserModalOpen: false,
  showDisableModal: false,
  verificationRequests: {},
};

const reducerMap = {
  [ActionTypes.SET_USERS]: (state, { users }) => {
    return {
      ...state,
      users: users.reduce((acc, user) => {
        if (user.username) {
          acc[user.username] = user;
        }
        return acc;
      }, {}),
    };
  },
  [ActionTypes.UPDATE_USER]: (state, { user }) => {
    state.users[user.username] = user;
    return { ...state };
  },
  [ActionTypes.SET_VERIFICATION_REQUESTS]: (state, { requests }) => {
    return {
      ...state,
      verificationRequests: requests.reduce((acc, request) => {
        if (request._id) {
          acc[request._id] = request;
        }
        return acc;
      }, {}),
    };
  },
  [ActionTypes.UPDATE_VERIFICATION_REQUESTS]: (state, { request }) => {
    state.verificationRequests[request._id] = {
      ...state.verificationRequests[request._id],
      ...request,
    };
    return { ...state };
  },
  [ActionTypes.SET_STORE_REQUESTS]: (state, { requests }) => {
    return {
      ...state,
      storeRequests: requests.reduce((acc, request) => {
        if (request._id) {
          acc[request._id] = request;
        }
        return acc;
      }, {}),
    };
  },
  [ActionTypes.UPDATE_STORE_REQUESTS]: (state, { request }) => {
    state.storeRequests[request._id] = {
      ...state.storeRequests[request._id],
      ...request,
    };
    return { ...state };
  },
  [ActionTypes.SET_PAGE_CLAIM_REQUESTS]: (state, { requests }) => {
    return {
      ...state,
      pageClaimRequests: requests.reduce((acc, request) => {
        if (request._id) {
          acc[request._id] = request;
        }
        return acc;
      }, {}),
    };
  },
  [ActionTypes.UPDATE_PAGE_CLAIM_REQUEST]: (state, { request }) => {
    state.pageClaimRequests[request._id] = {
      ...state.pageClaimRequests[request._id],
      ...request,
    };
    return { ...state };
  },
  [ActionTypes.SET_SELECTED_USER]: (state, { user }) => {
    return { ...state, selectedUser: user };
  },
  [ActionTypes.TOGGLE_USER_MODAL]: (state) => {
    return { ...state, isUserModalOpen: !state.isUserModalOpen };
  },
  [ActionTypes.SHOW_DISABLE_MODAL]: (state) => {
    return { ...state, showDisableModal: !state.showDisableModal };
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  return action.type in reducerMap
    ? reducerMap[action.type]({ ...state }, action)
    : state;
};

export default reducer;
