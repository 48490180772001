export const ActionTypes = {
   SET_USERS: "SET_USERS",
   SET_VERIFICATION_REQUESTS: "SET_VERIFICATION_REQUESTS",
   SET_PAGE_CLAIM_REQUESTS: "SET_PAGE_CLAIM_REQUESTS",
   SET_STORE_REQUESTS: "SET_STORE_REQUESTS",
   UPDATE_USER: "UPDATE_USER",
   UPDATE_VERIFICATION_REQUESTS: "UPDATE_VERIFICATION_REQUESTS",
   UPDATE_PAGE_CLAIM_REQUEST: "UPDATE_PAGE_CLAIM_REQUEST",
   UPDATE_STORE_REQUESTS: "UPDATE_STORE_REQUESTS",
   SET_SELECTED_USER: "SET_SELECTED_USER",
   TOGGLE_USER_MODAL: "TOGGLE_USER_MODAL",
   SHOW_DISABLE_MODAL: "SHOW_DISABLE_MODAL",
};
