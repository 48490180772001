import React from 'react';
import { FaBell, FaSearch } from 'react-icons/fa';
import { useAppState } from '../contexts/app';

const TopBar = () => {
  const appState = useAppState();

  return (
    <div className="flex w-full justify-between items-center mb-10">
      <h2 className="font-bold tracking-wide text-2xl">
        {appState.state.actualPage.name}
      </h2>
      <div className="flex items-center">
        <FaSearch className="text-gray-500 mr-4" />
        <FaBell className="text-gray-500 mr-4" />
        <div className="border-l border-gray-500 h-8 mr-4" />
        <div className="flex items-center">
          <div className="mr-2">
            {`${appState.state.user.first_name} ${appState.state.user.last_name}`}
          </div>
          <div className="h-10 w-10 border border-gray-500 rounded-full flex justify-center items-center">
            <img
              src={appState.state.user.profile_photo}
              alt={appState.state.user.username}
              className="object-cover h-10 w-10 p-1 rounded-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
