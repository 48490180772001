import React, { forwardRef, useImperativeHandle } from "react";
import shortUuid from "short-uuid";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { VscTriangleDown } from "react-icons/vsc";

const Paginator = (
  { limit, setLimit, page, setPage, itemsCount, initialLimit },
  ref
) => {
  const limitQuantities = [
    initialLimit * 1,
    initialLimit * 2,
    initialLimit * 3,
    initialLimit * 4,
    initialLimit * 5,
    initialLimit * 6,
  ];

  useImperativeHandle(ref, () => ({
    handleChangePage: handleChangePage,
  }));

  const handleChangePage = (type) => {
    if (type === "forward") {
      if (limit * page >= itemsCount) {
        return;
      }
      setPage(page + 1);
    } else {
      if (page > 1) {
        setPage(page - 1);
      }
    }
  };
  return (
    <div className="flex items-center justify-end">
      <span className="text-xs text-gray-700 mr-12 inline-flex items-center">
        Filas por página
        <div className="inline-block relative">
          <select
            value={limit}
            onChange={(event) => {
              const value = event.target.value;
              if (value !== limit) {
                setPage(1);
                setLimit(value);
              }
            }}
            className="block cursor-pointer appearance-none px-4 pr-5 text-black text-sm leading-tight focus:outline-none "
          >
            {limitQuantities.map((limitQuantity) => {
              return (
                <option
                  key={`table_limit__${shortUuid.generate()}`}
                  value={limitQuantity}
                >
                  {limitQuantity}
                </option>
              );
            })}
          </select>
        </div>
      </span>
      <span className="text-xs text-gray-700 mr-4">
        {page * limit - limit + 1}-
        {page * limit <= itemsCount ? page * limit : itemsCount} de {itemsCount}
      </span>
      <div className="inline-flex">
        <FiChevronLeft
          onClick={() => handleChangePage("back")}
          className="text-2xl text-gray-600 cursor-pointer"
        />
        <FiChevronRight
          onClick={() => handleChangePage("forward")}
          className="text-2xl text-gray-600 cursor-pointer"
        />
      </div>
    </div>
  );
};

export default forwardRef(Paginator);
