import { ActionTypes } from './types';

const AppActions = (dispatch) => {
  return {
    setActualPage: (page) => {
      dispatch({ type: ActionTypes.SET_ACTUAL_PAGE, page });
    },
    setUser: (user) => {
      dispatch({ type: ActionTypes.SET_USER, user });
    },
    setSelectedReport: (selectedReport) => {
      dispatch({ type: ActionTypes.SET_SELECTED_REPORT, selectedReport });
    },
  };
};

export default AppActions;
